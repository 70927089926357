// ----- Constants ----- 
import { jobs } from '../jobs';
import { technos } from '../technos';

// ----- Images ----- 
// import Uipath from '../../../images/partners/UiPath-logo.png';
import UipathPartnerSilver from '../../../images/partners/UiPath_Partner.png';
import Winshuttle from '../../../images/partners/winshuttle.png';
import AutomationAnywhere from '../../../images/partners/automation_anywhere.png';
import PowerAutomate from '../../../images/partners/Power-Automate.png';
import BluePrism from '../../../images/partners/blueprism-logo.png';
import MicrosoftDynamic from '../../../images/partners/dynamics.png';
import Oracle from '../../../images/partners/Oracle.png';
import PowerBi from '../../../images/partners/Power-BI.png';
import SalesForce from '../../../images/partners/salesforce-logo.png';
import Sap from '../../../images/partners/SAP.png';
import Sharepoint from '../../../images/partners/sharepoint.png';
import SqlServer from '../../../images/partners/sql_server.png';
import TalendHigh from '../../../images/partners/talend-high.png';
import WinshuttleCertif from '../../../images/partners/Winshuttle_Certif.png';
import TableauPartner from '../../../images/partners/Tableau-Partner.png';
import Celonis from '../../../images/partners/Celonis.png';
import QlikSense from '../../../images/partners/qlik-sense.png';
import MicrosoftPlatform from '../../../images/partners/microsoft_platform.png';
import Aymax from '../../../images/partners/aymax.png';
import Magellan from '../../../images/partners/magellan.png';
import Almond from '../../../images/partners/almond.png';
import TaskMining from '../../../images/partners/taskMining.png';
import ProcessMining from '../../../images/partners/uipath-process-mining.png';

// _______IA solutions
import Tensorflow from '../../../images/partners/tensorflow.png';
import PyTorch from '../../../images/partners/pyTorch.png';
import SeaBorn from '../../../images/partners/seaborn.png';
import Pandas from '../../../images/partners/pandas.png';
import Matplotlib from '../../../images/partners/matplotlib.png';
import HuggingFace from '../../../images/partners/hugging-face.png';
import ScikitLearn from '../../../images/partners/scikit_learn.png';
import OpenCv from '../../../images/partners/open-cv.png';

// ______ Dev Ops solutions
import MicrosoftAzure from '../../../images/partners/microsoft-azure.png';
import Gcp from '../../../images/partners/cloud-plateform.png';
import Kubernetes from '../../../images/partners/kubernetes.png';
import Docker from '../../../images/partners/docker.png';
import Heroku from '../../../images/partners/heroku.png';
import Selenium from '../../../images/partners/selenium.png';
import Aws from '../../../images/partners/aws.png';
import PowerShell from '../../../images/partners/powershell.png';
import Python from '../../../images/partners/python.png';
import Github from '../../../images/partners/github.png';
import Jira from '../../../images/partners/jira.png';
import Slack from '../../../images/partners/slack.png';



// ----- Packages ----- 
import { v4 as uuidv4 } from 'uuid';


export const partners = [
    {
        id: 1,
        jobs: [jobs.FINANCE],
        technos: [technos.RPA],
        image: Winshuttle,
        alt: "Winshuttle",
        title: "Winshuttle",
        description: "Winshuttle aide à migrer, charger et extraire les données SAP facilement, grâce à des scripts puissants, une solution de workflow intégrée et une interface web.",
        size: "xs",
        key: uuidv4(),
    },
    {
        id: 3,
        jobs: [jobs.IT, jobs.FINANCE],
        technos: [technos.RPA],
        image: UipathPartnerSilver,
        alt: "UI Path Partner",
        size: "xs",
        key: uuidv4(),
        title: "UiPath",
        description: "UiPath est le leader des plateformes Enterprise Automation et permet la détection de processus robotisables, le développement de robots logiciels, la supervision et planification de robots, le déploiement d'apps pour les utilisateurs.",

    },
    {
        id: 4,
        jobs: [jobs.RH, jobs.IT],
        technos: [technos.RPA],
        image: AutomationAnywhere,
        alt: "Automation Anywhere",
        title: "Automation Anywhere",
        description: "Automaltion Anywhere est une forme d'automatisation cloud qui permet de développer des robots logiciels afin d'automatiser des tâches numériques en quelques minutes.",
        size: "md",
        key: uuidv4(),
    },
    {
        id: 5,
        jobs: [jobs.IT, jobs.RH],
        technos: [technos.RPA],
        image: PowerAutomate,
        alt: "Power Automate",
        title: "Power Automate",
        description: "Power Automate est une plate-forme low-code qui permet aux utilisateurs  d'optimiser leurs flux de travail et d'automatiser les tâches répétitives et chronophages.",
        size: "xl",
        key: uuidv4(),
    },
    {
        id: 6,
        jobs: [jobs.IT, jobs.RH],
        technos: [],
        image: BluePrism,
        alt: "Blue Prism",
        size: "xl",
        key: uuidv4(),
    },
    {
        id: 7,
        jobs: [jobs.IT, jobs.RH],
        technos: [technos.RPA],
        image: MicrosoftDynamic,
        alt: "Microsoft Dynamics",
        size: "xl",
        key: uuidv4(),
    },
    {
        id: 8,
        jobs: [jobs.IT, jobs.RH],
        technos: [],
        image: Oracle,
        alt: "Oracle",
        size: "xl",
        key: uuidv4(),
    },
    {
        id: 9,
        jobs: [jobs.IT, jobs.RH],
        technos: [technos.BI],
        image: PowerBi,
        alt: "Power BI",
        title: "Power BI",
        description: "Power BI est une application gratuite qui s'installe sur un ordinateur local et permet de se connecter à des données, de les transformer et de les visualiser..",
        size: "xl",
        key: uuidv4(),
    },
    {
        id: 10,
        jobs: [jobs.IT, jobs.RH],
        technos: [],
        image: SalesForce,
        alt: "Sales Force",
        size: "md",
        key: uuidv4(),
    },
    {
        id: 11,
        jobs: [jobs.IT, jobs.RH],
        technos: [],
        image: Sap,
        alt: "SAP",
        size: "xs",
        key: uuidv4(),
    },
    {
        id: 12,
        jobs: [jobs.IT, jobs.RH],
        technos: [],
        image: Sharepoint,
        alt: "Sharepoint",
        size: "xl",
        key: uuidv4(),
    },
    {
        id: 13,
        jobs: [jobs.IT, jobs.RH],
        technos: [technos.RPA],
        image: SqlServer,
        alt: "SQL Server",
        size: "xl",
        key: uuidv4(),
    },
    {
        id: 14,
        jobs: [jobs.IT, jobs.RH],
        technos: [],
        image: TalendHigh,
        alt: "Talend High",
        size: "md",
        key: uuidv4(),
    },
    {
        id: 15,
        jobs: [jobs.IT, jobs.RH],
        technos: [technos.RPA],
        image: WinshuttleCertif,
        alt: "Winshuttle Certif",
        size: "xs",
        key: uuidv4(),
    },
    {
        id: 16,
        jobs: [jobs.IT, jobs.RH],
        technos: [technos.BI],
        image: TableauPartner,
        alt: "Tableau Partner",
        title: "Tableau Software",
        description: "Tableau offre une approche révolutionnaire de la BI pour connecter, partager et visualiser vos données.",
        size: "md",
        key: uuidv4(),
    },
    {
        id: 17,
        jobs: [jobs.IT, jobs.RH],
        technos: [technos.RPA],
        image: MicrosoftPlatform,
        alt: "Microsoft Power Platform",
        title: 'Microsoft Power Platform',
        description: "Microsoft Power Platform est une solutions permettant de développer des applications grâce à une plateforme intuitive, collaborative et extensible d’outils « low code »",
        size: "xl",
        key: uuidv4(),

    },
    {
        id: 18,
        jobs: [jobs.IT, jobs.RH],
        technos: [technos.BPM],
        image: Celonis,
        alt: "Celonis",
        title: 'Celonis',
        description: "Celonis relie les données de tous vos systèmes, applications et postes de travail en vous donnant une vision de l'ensemble de vos processus et comment ils s'exécutent.",
        size: "md",
        key: uuidv4(),
    },
    {
        id: 19,
        jobs: [jobs.IT, jobs.RH],
        technos: [technos.BI],
        image: QlikSense,
        alt: "Qlik Sense",
        title: 'Qlik Sense',
        description: "Qlik Sense est une solution de Data Viz conçue pour les entreprises qui souhaitent créer rapidement des visualisations et explorer leur data en profondeur.",
        size: "xl",
        key: uuidv4(),

    },

    {
        id: 20,
        jobs: [jobs.IT, jobs.RH],
        technos: [],
        image: Magellan,
        alt: "Magellan",
        title: 'Magellan',
        description: "Magellan Consulting est un cabinet de conseil en stratégie, management et SI spécialisé dans la transformation digitale des entreprises.",
        size: "xs",
        key: uuidv4(),
        type: "advice"

    },
    {
        id: 21,
        jobs: [jobs.IT, jobs.RH],
        technos: [],
        image: Almond,
        alt: "Almond",
        title: 'Almond',
        description: "Almond est un acteur français indépendant incontournable de l'audit et du conseil dans en Cybersécurité, Cloud et Infrastructures.",
        size: "xs",
        key: uuidv4(),
        type: "advice"


    },
    {
        id: 22,
        jobs: [jobs.IT, jobs.RH],
        technos: [],
        image: Aymax,
        alt: "Aymax",
        title: 'Aymax',
        description: "Aymax est un groupe expert dans le conseil, le développement d’applications digitales et l’intégration des solutions SAP.",
        size: "xs",
        key: uuidv4(),
        type: "advice"
    },
    {
        id: 23,
        jobs: [],
        technos: [technos.BPM],
        image: TaskMining,
        alt: "UI Path Task mining",
        size: "xl",
        key: uuidv4(),
    },
    {
        id: 24,
        jobs: [],
        technos: [technos.BPM],
        image: ProcessMining,
        alt: "UI Path Process mining",
        size: "md",
        key: uuidv4(),
    },
    //_______IA solutions
    {
        id: 25,
        jobs: [],
        technos: [technos.IA],
        image: Tensorflow,
        alt: "Tensor Flow",
        size: "xl",
        key: uuidv4(),
    },
    {
        id: 26,
        jobs: [],
        technos: [technos.IA],
        image: PyTorch,
        alt: "Py Torch",
        size: "xl",
        key: uuidv4(),
    },
    {
        id: 27,
        jobs: [],
        technos: [technos.IA],
        image: SeaBorn,
        alt: "Sea Born",
        size: "xs",
        key: uuidv4(),
    },
    {
        id: 28,
        jobs: [],
        technos: [technos.IA],
        image: Pandas,
        alt: "Pandas",
        size: "md",
        key: uuidv4(),
    },
    {
        id: 29,
        jobs: [],
        technos: [technos.IA],
        image: Matplotlib,
        alt: "Matplotlib",
        size: "md",
        key: uuidv4(),
    },
    {
        id: 30,
        jobs: [],
        technos: [technos.IA],
        image: HuggingFace,
        alt: "Hugging face",
        size: "md",
        key: uuidv4(),
    },
    {
        id: 31,
        jobs: [],
        technos: [technos.IA],
        image: ScikitLearn,
        alt: "Scikit Learn",
        size: "md",
        key: uuidv4(),
    },
    {
        id: 32,
        jobs: [],
        technos: [technos.IA],
        image: OpenCv,
        alt: "Open CV",
        size: "md",
        key: uuidv4(),
    },
    //_______Dev Ops solutions
    
    {
        id: 33,
        jobs: [],
        technos: [technos.DEVOPS],
        image: MicrosoftAzure,
        alt: "Microsoft Azure",
        size: "md",
        key: uuidv4(),
    },
    {
        id: 34,
        jobs: [],
        technos: [technos.DEVOPS],
        image: Gcp,
        alt: "Google Cloud Platform",
        size: "md",
        key: uuidv4(),
    },
    {
        id: 35,
        jobs: [],
        technos: [technos.DEVOPS],
        image: Kubernetes,
        alt: "Kubernetes",
        size: "md",
        key: uuidv4(),
    },
    {
        id: 36,
        jobs: [],
        technos: [technos.DEVOPS],
        image: Docker,
        alt: "Docker",
        size: "md",
        key: uuidv4(),
    },
    {
        id: 37,
        jobs: [],
        technos: [technos.DEVOPS],
        image: Heroku,
        alt: "Heroku",
        size: "md",
        key: uuidv4(),
    },
    {
        id: 38,
        jobs: [],
        technos: [technos.DEVOPS],
        image: Selenium,
        alt: "Selenium",
        size: "md",
        key: uuidv4(),
    },
    {
        id: 39,
        jobs: [],
        technos: [technos.DEVOPS],
        image: Aws,
        alt: "Amazon Web Service",
        size: "xs",
        key: uuidv4(),
    },
    {
        id: 40,
        jobs: [],
        technos: [technos.DEVOPS],
        image: PowerShell,
        alt: "PowerShell",
        size: "xs",
        key: uuidv4(),
    },
    {
        id: 41,
        jobs: [],
        technos: [technos.DEVOPS],
        image: Python,
        alt: "Python",
        size: "md",
        key: uuidv4(),
    },
    {
        id: 42,
        jobs: [],
        technos: [technos.DEVOPS],
        image: Github,
        alt: "Github",
        size: "md",
        key: uuidv4(),
    },
    {
        id: 43,
        jobs: [],
        technos: [technos.DEVOPS],
        image: Jira,
        alt: "Jira",
        size: "xl",
        key: uuidv4(),
    },
    {
        id: 44,
        jobs: [],
        technos: [technos.DEVOPS],
        image: Slack,
        alt: "Slack",
        size: "md",
        key: uuidv4(),
    },

];