let entityMap = {
    '&amp;': '&',
    '&lt;': '<',
    '&gt;': '>',
    '&quot;': '"',
    '&#39;': "'",
    '&#x2F;': '/',
    '&#x60;': '`',
    '&#x3D;': '=',
    '&#8230;' : '...',
    '&rsquo;': "'",
    "&#8217;": "'"
};

export function unescapeHtml(string) {
    return String(string).replace(/&amp;|&lt;|&gt;|&quot;|&#39;|&#x2F;|&#x60;|&#x3D;|&#8230;|&rsquo;|&#8217;/g, function (s) {
        return entityMap[s];
    });
}

