import React from 'react'

// ----- Images -----
import RosaceOrange from '../../images/logos/rosace-orange.png'
import RosaceGreen from '../../images/logos/rosace-green.png'

export default function CorporateValues() {
    return (
        <div id="corporateValues">

            <div id="ourValues">
                <div className="title">
                    <img className="rosace" src={RosaceOrange} alt={"rosace orange du logo TED consulting"} />
                    <h2>Nos valeurs</h2>
                </div>

                <div className="container-components ">
                    <div className="container-centered">
                        <div className="component-ourValues blue-component">
                            <p>Intrapreneur</p>
                        </div>
                        <div className="component-ourValues green-component">
                            <p>Apprentissage</p>
                        </div>
                        <div className="component-ourValues dark-green-component">
                            <p>Créativité</p>
                        </div>
                        <div className="component-ourValues dark-component">
                            <p>Empathie</p>
                        </div>
                        <div className="component-ourValues blue-component">
                            <p>Confiance</p>
                        </div>
                    </div>
                </div>

            </div>

            <div id="trust">
                <div className="title">
                    <img className="rosace" src={RosaceGreen} alt={"rosace orange du logo TED consulting"} />
                    <h2>Pourquoi nos clients nous font confiance?</h2>
                </div>

                <div className="container-components container-centered">
                    <div className="component-trust">
                        <p>Nous avons une forte culture intrapreunariale, et sommes un lab d’innovation pour nos clients (CII)</p>
                    </div>
                    <div className="component-trust">
                        <p>Nous avons de nombreuses références clients dans de multiples secteurs</p>
                    </div>
                    <div className="component-trust">
                        <p>Nous sommes le seul cabinet de conseil  en France spécialiste pure player HyperAutomation </p>
                    </div>
                    <div className="component-trust">
                        <p>Nous avons su intégrer une offre produits  www.aliz.io à notre offre de consulting (service) TED</p>
                    </div>
                    <div className="component-trust">
                        <p>Nous avons une  forte culture de la Data et réalisons des projets stratégies BI & Data Viz</p>
                    </div>
                    <div className="component-trust">
                        <p>Nous formons les clients et réalisons des webinaires. Nous sommes certifiés Datadock</p>
                    </div>
                    <div className="component-trust">
                        <p>Partenaires certifiés et revendeurs de solutions Automation.</p>
                    </div>
                </div>
                
            </div>
        </div>
    )
}
