import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'


// ----- Images -----
import Icon from '../../images/icons/arrow-down.svg'

// ----- Services -----
import { revealText } from '../../services/helpers/revealText'
import { animationDirection } from '../../services/constants/animationDirection'

// ----- Component ----- 
import ContactUs from '../ContactUs'


export default function Presentation({ image, alt, title, description, description2 }) {

    const leftSide = useRef()
    const rightSide = useRef()
    const firstPage = useRef()


    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);


    useEffect(() => {
        revealText(leftSide.current, animationDirection.TOLEFT, 0, 1)
    }, [])


    return (
        <div className="page container-centered reverse" ref={firstPage} id="firstBlock-presentation">

            <div className="illustration-side" ref={rightSide}>
                <img src={image} alt={alt} />
            </div>

            <div className="one-side reveal-text" ref={leftSide}>
                <h1 className="title">{title && title}</h1>
                <p className="description">
                    {description && description}
                    <br /><br />
                    {description2 && description2}</p>
                <button className="btn-default btn-orange" onClick={handleShow}>En savoir plus</button>

            </div>



            <Link id="scroll-arrow" to={'#prestations'}>
                <img src={Icon} alt="flèche vers le bas" />
            </Link>

            <ContactUs show={show} setShow={setShow}/>

        </div>
    )
}
