import React from 'react'

// ----- Component -----
import Presentation from '../components/courses/Presentation'
import AllCourses from '../components/courses/AllCourses'

// ----- Packages -----
import { Helmet, HelmetProvider } from 'react-helmet-async';

export default function Courses() {

  return (
    <HelmetProvider>

      <Helmet prioritizeSeoTags>
        <title>Nos formations</title>
        <meta name="description" content="Découvrez les formations Ted sur la RPA, Data visualisation, BPM, gestion de projet...t" />
        <meta property="og:title" content="Nos formations" />
        <meta property="og:description" content="Découvrez les formations Ted sur la RPA, Data visualisation, BPM, gestion de projet..." />
      </Helmet>

      <div className="page" id="coursesPage">

        <Presentation />
        <AllCourses />

      </div>
    </HelmetProvider>
  )
}
