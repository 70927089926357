import React, { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'

// ----- Images -----
import JobsIllustration from '../../images/project/presentation/projets.svg'
import Icon from '../../images/icons/arrow-down.svg'

// ----- Services -----
import { revealText } from '../../services/helpers/revealText'
import { animationDirection } from '../../services/constants/animationDirection'

export default function Presentation() {


    const leftSide = useRef()
    const rightSide = useRef()
    const firstPage = useRef()

    useEffect(() => {
        revealText(leftSide.current, animationDirection.TOLEFT, 0, 1)
    }, [])


    return (
        <div className="page container-centered reverse" ref={firstPage} id="firstBlock-presentation">

            <div className="illustration-side" ref={rightSide}>
                <img src={JobsIllustration} alt="Groupe d'hommes et de femmes faisant un pouce" />
            </div>

            <div className="one-side " ref={leftSide}>
                <div className="reveal-text">
                    <h1 className="title">Nos projets</h1>
                    <p className="description">
                        Nous sommes convaincus que pour se lancer dans des projets d'innovations, d'automatisation,
                        de transformation digitale ou encore de Data, il est important de pouvoir se projeter avec du concret,
                        avoir des informations pragmatiques et une vision sur la rentabilité et le ROI.
                        <br />
                        Les cas d'usages ci-dessous, devraient répondre à certaines de vos questions.
                        <br />
                        Bonne lecture !
                    </p>
                    <div className="kpi-component">
                        <div>
                            <p className="number-kpi">+112</p>
                            <p className="type-kpi">Projets réalisés</p>
                        </div>

                        <div>
                            <p className="number-kpi">+11 500</p>
                            <p className="type-kpi">Heures économisées</p>
                        </div>
                    </div>
                </div>
            </div>



            <Link id="scroll-arrow" to={'/nos-projets/#secteurs'}>
                <img src={Icon} alt="flèche vers le bas" />
            </Link>
        </div>
    )
}
