import React from 'react'
import { Link } from 'react-router-dom'


export default function Resources() {
    return (
        <>
            <div className="list-item-dropdown">
                <div className="item-dropdown">
                    <div className="list-resources">

                        <div className="item-resources">
                            <b>Ressources</b>

                            <Link to="/blog">Blog</Link>
                            <Link to="/partenaires">Nos partenaires</Link>
                            <Link to="/formations">Nos formations</Link>
                            <Link to="/portfolio">Portfolio</Link>
                            <Link to={"/conditions-et-politiques"}>Conditions et politiques</Link>
                        </div>

                        {/* <div className="item-resources">
                            <b>Blog</b>
                            <Link to="/">Article</Link>
                            <Link to="/">Glossaire</Link>
                            <Link to="/">Livres blancs</Link>
                        </div> */}
                    </div>

                </div>
            </div>
        </>
    )
}
