import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

// ----- Constants -----
import { projects } from '../services/constants/components/projects'

// ----- Components -----
import Presentation from '../components/project/Presentation'
import ContextAndProcess from '../components/project/ContextAndProcess'
import Tools from '../components/project/Tools'


// ----- Packages -----
import { Helmet, HelmetProvider } from 'react-helmet-async';

export default function Project() {
  const [findProject, setFindProject] = useState("")
  const params = useParams();

  useEffect(() => {
    setFindProject(projects.filter(project => project.slug === params.slug)[0])
  }, [params])


  return (
    <HelmetProvider>

      <Helmet prioritizeSeoTags>
        <title>{findProject && "Projet - " + findProject.title}</title>
        <meta name="description" content={findProject && findProject.description.slice(0, 100) + "... "} />
        <meta property="og:title" content={findProject && "Projet - " + findProject.title} />
        <meta property="og:description" content={findProject && findProject.description.slice(0, 100) + "... "} />
      </Helmet>

      <div id="projectPage">

        <Presentation project={findProject} />
        <ContextAndProcess project={findProject} />

        {findProject.available && <Tools project={findProject} />}

      </div>
    </HelmetProvider>
  )
}
