import React, { useEffect, useState } from 'react'

// ----- Constants -----
import { sectorsLabel, jobsLabel } from '../../services/constants/components/labelFilter'

import { projects } from '../../services/constants/components/projects'

// ----- Component -----
import LabelSector from './LabelSector'
import LabelJob from './LabelJob'


export default function FilterProjectsBy({ setFilteredArray, setSelectedSectors, selectedSectors, setSelectedJobs, selectedJobs, query }) {

    const [isActiveSectors, setIsActiveSectors] = useState("")
    const [isActiveJobs, setIsActiveJobs] = useState("")
    const [isLoaded, setIsLoaded] = useState(false)
    //  @description determine if an array contains one or more items from another array.
    //  @param {array} haystack the array to search.
    //  @param {array} arr the array providing items to check for in the haystack.
    //  @return {boolean} true|false if haystack contains at least one item from arr.
    const filterSelectedSectors = (sector, arr) => {
        return arr.some(v => sector === v);
    };
    const filterSelectedJobs = (job, arr) => {
        return arr.some(v => job === v);
    };


    useEffect(() => {
        setIsLoaded(true)
    }, [])

    useEffect(() => {
        if (isLoaded) {
            if (query.get("sectors")) {
                setSelectedSectors(arr => [...arr, query.get("sectors")])
            } else if (query.get("jobs")) {
                setSelectedJobs(arr => [...arr, query.get("jobs")])
            }
        }
        // eslint-disable-next-line
    }, [isLoaded])



    // _____ FILTER ARRAY PROJECTS _____
    useEffect(() => {
        setFilteredArray([])

        projects.forEach(project => {
            let findBySector = false
            let findByJob = false

            if (filterSelectedSectors(project.sector, selectedSectors)) {
                findBySector = true
            }
            if (filterSelectedJobs(project.job, selectedJobs)) {
                findByJob = true
            }


            if (selectedSectors.length === 1 && selectedJobs.length === 1) {
                if (findBySector && findByJob) {
                    setFilteredArray(arr => [...arr, project]);
                }
            } else if (selectedSectors.length === 0 && selectedJobs.length === 0) {
                setFilteredArray(arr => [...arr, project]);
            } else {
                if (findBySector || findByJob) {
                    setFilteredArray(arr => [...arr, project]);
                }
            }

        });

        // eslint-disable-next-line
    }, [selectedJobs, selectedSectors])


    //  _____ BUTTON ACTIVE ALL _____
    const activeAllSectors = () => {
        setIsActiveSectors(isActiveSectors ? false : true)
    }

    const activeAlljobs = () => {
        setIsActiveJobs(isActiveJobs ? false : true)
    }

    useEffect(() => {
        if (isActiveSectors) {
            sectorsLabel.forEach(sector => {
                if (!selectedSectors.includes(sector))
                    setSelectedSectors(arr => [...arr, sector.value]);
            });
        } else {
            setSelectedSectors([]);
        }
        // eslint-disable-next-line
    }, [isActiveSectors])

    useEffect(() => {
        if (isActiveJobs) {
            jobsLabel.forEach(job => {
                if (!selectedSectors.includes(job)) {
                    setSelectedJobs(arr => [...arr, job.value]);
                }
            });
        } else {
            setSelectedJobs([]);
        }
        // eslint-disable-next-line
    }, [isActiveJobs])
    //  _____ END BUTTON ACTIVE ALL _____


    return (
        <div id="all-filter">

            <div className="container-centered">

                <div className="one-side">
                    <h3 className="title">Secteurs</h3>

                    <div className="container-label-filtersBy">
                        <button
                            className={`btn-label btn-selector-all ${isActiveSectors ? "is-active" : ""}`}
                            onClick={activeAllSectors}
                        >
                            Tous
                        </button>

                        {sectorsLabel.map((label, index) =>
                            <LabelSector
                                label={label}
                                selectedSectors={selectedSectors}
                                setSelectedSectors={setSelectedSectors}
                                isActiveSectors={isActiveSectors}
                                index={index}
                            />
                        )}
                    </div>
                </div>

                <div className="one-side">
                    <h3 className="title">Métiers</h3>

                    <div className="container-label-filtersBy">

                        <button
                            className={`btn-label btn-selector-all ${isActiveJobs ? "is-active" : ""}`}
                            onClick={activeAlljobs}
                        >
                            Tous
                        </button>

                        {jobsLabel.map((label, index) =>
                            <LabelJob
                                label={label}
                                selectedJobs={selectedJobs}
                                setSelectedJobs={setSelectedJobs}
                                isActiveJobs={isActiveJobs}
                                index={(index + 10)}
                            />
                        )}
                    </div>
                </div>

            </div>
        </div >
    )
}
