import React from 'react'

// ----- Component -----
import Presentation from '../components/portfolio/Presentation'
import AllVideos from '../components/portfolio/AllVideos'

// ----- Packages -----
import { Helmet, HelmetProvider } from 'react-helmet-async';

export default function Portfolio() {
  return (
    <HelmetProvider>

      <Helmet prioritizeSeoTags>
        <title>Portfolio</title>
        <meta name="description" content="Découvrez le portfolio Ted - Démos RPA et DataViz, Webinaires, Podcasts ..." />
        <meta property="og:title" content="Portfolio" />
        <meta property="og:description" content="Découvrez le portfolio Ted - Démos RPA et DataViz, Webinaires, Podcasts ..." />
      </Helmet>

      <div className="page" id="portfolioPage">

        <Presentation />
        <AllVideos />

      </div>
    </HelmetProvider>
  )
}
