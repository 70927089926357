import React, { useEffect, useRef } from 'react'

// ----- Components -----
import Presentation from '../components/projectsFilter/Presentation'
import Projects from '../components/projectsFilter/Projects'
import VideoBSmart from '../components/VideoBSmart'

// ----- Packages -----
import { Helmet, HelmetProvider } from 'react-helmet-async';

export default function ProjectsFilter({ location }) {

  const sectorsRef = useRef()

  useEffect(() => {
    switch (location.hash) {
      case "#secteurs":
        sectorsRef.current.scrollIntoView()
        break;

      default:
        break;
    }
  }, [location])

  return (
    <HelmetProvider>

      <Helmet prioritizeSeoTags>
        <title>Nos projets</title>
        <meta name="description" content="Découvrez les projets Ted consulting - Tous nos cas d'usages RPA, BPM, DATA, IA et DevOps" />
        <meta property="og:title" content="Nos projets - Uses Cases" />
        <meta property="og:description" content="Découvrez les projets Ted consulting - Tous nos cas d'usages RPA, BPM, DATA, IA et DevOps" />
      </Helmet>

      <div id="projectFilter">

        {/* ----- First Page - Presentation TED ----- */}
        <Presentation />

        {/* ----- Projects -----*/}
        <Projects sectorsRef={sectorsRef} />

        {/* ----- Vidéo B Smart -----*/}
        <VideoBSmart />

      </div >

    </HelmetProvider>
  )
}
