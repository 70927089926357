import React from 'react'


// ----- Images -----
import RosaceBlue from '../../images/logos/rosace-blue.png'

export default function SomeNumbers() {
    return (
        <>
            <div id="some-numbers">
                <div className="title">
                    <img className="rosace" src={RosaceBlue} alt={"rosace bleue du logo TED consulting"} />
                    <h2>Quelques chiffres</h2>
                </div>

                <div className="all-numbers">
                    <div className="number-component blue-component">
                        <div className="hr"></div>
                        <p className="title-key">Lancement</p>
                        <p className="number">2016</p>
                        <p className="description-key">6ème année de projets délivrés chez nos clients.
                        </p>
                    </div>

                    <div className="number-component green-component">
                        <div className="hr"></div>
                        <p className="title-key">Nombre de clients</p>
                        <p className="number">+40</p>
                        <p className="description-key">
                            Clients du CAC40 ou grands groupes internationaux en majorité.
                            Nous accompagnons aussi de nombreuses PME et TPE.
                        </p>
                    </div>

                    <div className="number-component dark-green-component">
                        <div className="hr"></div>
                        <p className="title-key">Nombre de Collaborateurs</p>
                        <p className="number">+25</p>
                        <p className="description-key">
                            Croissance rapide de notre équipe, nous recrutons des consultant.es certifié.es et spécialistes
                        </p>
                    </div>

                    <div className="number-component dark-component">
                        <div className="hr"></div>
                        <p className="title-key">Projets HyperAutomation</p>
                        <p className="number">+100</p>
                        <p className="description-key">
                            Projets délivrés chez nos clients, en France principalement mais aussi dans des filiales et entités
                            en Europe et Amériques. Nos équipes parlent Français, Anglais, Arabe
                        </p>
                    </div>
                </div>
                
            </div>
        </>
    )
}
