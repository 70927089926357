// ----- Constants ----- 
import { videoTypes } from '../videoTypes';

// ----- Images ----- 
// ----- UI path ----- 
import GestionRetard from '../../../images/videos/gestion_retard.png'
import AnnulationCommande from '../../../images/videos/annulation_commande.png'
import EmbaucheRh from '../../../images/videos/embauche_rh.png'
import TransformationCommandeAchat from '../../../images/videos/transformation_commande_achat_sap.png'
import CreationDemandeAchat from '../../../images/videos/creation_demande_achat.png'
import OdSalaire from '../../../images/videos/od_salaire.png'
import TelechargementBorderau from '../../../images/videos/telechargement_bordereau.png'
import EcriturePaie from '../../../images/videos/ecriture_paie.png'

// ----- AutomationAnywhere ----- 
import ValiditeDonnees from '../../../images/videos/validite_donnees.png'
import CreationFactureCrm from '../../../images/videos/creation_facture_crm.png'

// ----- PowerBi ----- 
import InfographicDesigner from '../../../images/videos/infographic_designer.png'
import StorePerformance from '../../../images/videos/store_performance.png'
import SupplierQuality from '../../../images/videos/supplier_quality.png'
import SalesPerformance from '../../../images/videos/sales_performance.png'
import ProfitAndLossAnalysis from '../../../images/videos/profit_loss_analysis.png'
import FinanceDashboard from '../../../images/videos/finance_dashboard.png'

// ----- TableauSoftware ----- 
// import DonutChart from '../../../images/videos/donut_chart.png'
import ToggleButton from '../../../images/videos/toggle_button.png'
import Pagination from '../../../images/videos/pagination.png'
import DivergingBar from '../../../images/videos/diverging_bar.png'
import UrlAction from '../../../images/videos/url_action.png'
import DateGranularity from '../../../images/videos/date_granularity.png'

// ----- TableauSoftware ----- 
import WebinarAlmond from '../../../images/videos/webinar_almond.png'


export const videos = [
    // playlist UIPATH
    // https://www.youtube.com/embed/videoseries?list=PLIHYDqC4NMyb0sSBqi29tRA690fKY7_sO

    {
        id: 1,
        type: videoTypes.UIPATH,
        link: "https://www.youtube.com/embed/A-OrHKYzgKI",
        title: "Gestion des retards de commandes clients avec UIPATH",
        href: "https://youtu.be/A-OrHKYzgKI",
        alt: "Vidéo youtube TED consulting - Gestion des retards de commandes clients avec UIPATH",
        img: GestionRetard,
    },
    {
        id: 2,
        type: videoTypes.UIPATH,
        link: "https://www.youtube.com/embed/g1JvFzM-FdI",
        title: "Annulation des commandes",
        href: "https://youtu.be/g1JvFzM-FdI",
        alt: "Vidéo youtube TED consulting - Annulation des commandes",
        img: AnnulationCommande,
    },
    {
        id: 3,
        type: videoTypes.UIPATH,
        link: "https://www.youtube.com/embed/g6-sE5t1w-A",
        title: "Téléchargement des bordereaux IJ",
        href: "https://youtu.be/g6-sE5t1w-A",
        alt: "Vidéo youtube TED consulting - Téléchargement des bordereaux IJ",
        img: TelechargementBorderau,
    },
    {
        id: 4,
        type: videoTypes.UIPATH,
        link: "https://www.youtube.com/embed/R8X0W0SVOdY",
        title: "Embauche RH",
        href: "https://youtu.be/R8X0W0SVOdY",
        alt: "Vidéo youtube TED consulting - Embauche RH",
        img: EmbaucheRh,
    },
    {
        id: 5,
        type: videoTypes.UIPATH,
        link: "https://www.youtube.com/embed/uu6TDHXSMtA",
        title: "OD Salaire",
        href: "https://youtu.be/uu6TDHXSMtA",
        alt: "Vidéo youtube TED consulting - OD Salaire",
        img: OdSalaire,
    },
    {
        id: 6,
        type: videoTypes.UIPATH,
        link: "https://www.youtube.com/embed/trdL4SlbYbk",
        title: "Écriture de paie",
        href: "https://youtu.be/trdL4SlbYbk",
        alt: "Vidéo youtube TED consulting - Écriture de paie",
        img: EcriturePaie,
    },
    {
        id: 7,
        type: videoTypes.UIPATH,
        link: "https://www.youtube.com/embed/DR6MWKnK1Xw",
        title: "Création d'une Commande d'achat dans SAP",
        href: "https://youtu.be/DR6MWKnK1Xw",
        alt: "Vidéo youtube TED consulting - Création d'une Commande d'achat dans SAP",
        img: CreationDemandeAchat,
    },
    {
        id: 8,
        type: videoTypes.UIPATH,
        link: "https://www.youtube.com/embed/iCgan5g6vNY",
        title: "Transformation demande d'achat en Commande SAP",
        href: "https://youtu.be/iCgan5g6vNY",
        alt: "Vidéo youtube TED consulting - Transformation demande d'achat en Commande SAP",
        img: TransformationCommandeAchat,
    },
    // AUTOMATIONANYWHERE
    {
        id: 9,
        type: videoTypes.AUTOMATIONANYWHERE,
        link: "https://www.youtube.com/embed/AxTXg_HPhdk",
        title: "Création de factures dans un CRM",
        href: "https://youtu.be/AxTXg_HPhdk",
        alt: "Vidéo youtube TED consulting - Création de factures dans un CRM",
        img: ValiditeDonnees,
    },
    {
        id: 10,
        type: videoTypes.AUTOMATIONANYWHERE,
        link: "https://www.youtube.com/embed/N5TMEJhumvI",
        title: "Data cleaning & Data enrichment",
        href: "https://youtu.be/N5TMEJhumvI",
        alt: "Vidéo youtube TED consulting - Data cleaning & Data enrichment",
        img: CreationFactureCrm,
    },
    // POWER BI 
    {
        id: 11,
        type: videoTypes.POWERBI,
        link: "https://www.youtube.com/embed/YSuEV4eEtb8",
        title: "Power BI tricks with Ted - Infographic Designer",
        href: "https://youtu.be/YSuEV4eEtb8",
        alt: "Vidéo youtube TED consulting - Power BI tricks with Ted - Infographic Designer",
        img: InfographicDesigner,
    },
    {
        id: 12,
        type: videoTypes.POWERBI,
        link: "https://www.youtube.com/embed/qaYfkqaBZXU",
        title: "Retail Dashboard - Store performance",
        href: "https://youtu.be/qaYfkqaBZXU",
        alt: "Vidéo youtube TED consulting - Retail Dashboard - Store performance",
        img: StorePerformance,

    },
    {
        id: 13,
        type: videoTypes.POWERBI,
        link: "https://www.youtube.com/embed/Ig6LFNGJhdU",
        title: "Supplier Quality Downtime Analysis",
        href: "https://youtu.be/Ig6LFNGJhdU",
        alt: "Vidéo youtube TED consulting - Supplier Quality Downtime Analysis",
        img: SupplierQuality,
    },
    {
        id: 14,
        type: videoTypes.POWERBI,
        link: "https://www.youtube.com/embed/MsWmQk03sjo",
        title: "Sales Performance Dashboard",
        href: "https://youtu.be/MsWmQk03sjo",
        alt: "Vidéo youtube TED consulting - Sales Performance Dashboard",
        img: SalesPerformance,
    },
    {
        id: 15,
        type: videoTypes.POWERBI,
        link: "https://www.youtube.com/embed/AKHosgrzvC0",
        title: "Profit and Loss Analysis Dashboard",
        href: "https://youtu.be/AKHosgrzvC0",
        alt: "Vidéo youtube TED consulting - Profit and Loss Analysis Dashboard",
        img: ProfitAndLossAnalysis,
    },
    {
        id: 16,
        type: videoTypes.POWERBI,
        link: "https://www.youtube.com/embed/HCzAIOhu-YI",
        title: "Finance Dashboard",
        href: "https://youtu.be/HCzAIOhu-YI",
        alt: "Vidéo youtube TED consulting - Finance Dashboard",
        img: FinanceDashboard,
    },
    // Tableau Software
    // {
    //     id: 17,
    //     type: videoTypes.TABLEAUSOFTWARE,
    //     link: "https://www.youtube.com/embed/-Zq7t1MFhQQ",
    //     title: "Tableau Tricks with TED - Donut Chart",
    //     href: "https://youtu.be/Zq7t1MFhQQ",
    //     alt: "Vidéo youtube TED consulting - Tableau Tricks with TED - Donut Chart",
    //     img: DonutChart,
    // },
    {
        id: 18,
        type: videoTypes.TABLEAUSOFTWARE,
        link: "https://www.youtube.com/embed/6uaS-g5fDEM",
        title: "Tableau Tricks with TED - Toggle Button",
        href: "https://youtu.be/6uaS-g5fDEM",
        alt: "Vidéo youtube TED consulting - Tableau Tricks with TED - Toggle Button",
        img: ToggleButton,
    },
    {
        id: 19,
        type: videoTypes.TABLEAUSOFTWARE,
        link: "https://www.youtube.com/embed/jtq89q9x27U",
        title: "Tableau Tricks with TED - Create Pagination",
        href: "https://youtu.be/jtq89q9x27U",
        alt: "Vidéo youtube TED consulting - Tableau Tricks with TED - Create Paginatio",
        img: Pagination,
    },
    {
        id: 20,
        type: videoTypes.TABLEAUSOFTWARE,
        link: "https://www.youtube.com/embed/32wI7OPup9w",
        title: "Tableau Tricks with TED - Diverging bar chart",
        href: "https://youtu.be/32wI7OPup9w",
        alt: "Vidéo youtube TED consulting - Tableau Tricks with TED - Diverging bar chart",
        img: DivergingBar,
    },
    {
        id: 21,
        type: videoTypes.TABLEAUSOFTWARE,
        link: "https://www.youtube.com/embed/S-0Abc2GhV4",
        title: "Tableau Tricks with TED - Go to action URL",
        href: "https://youtu.be/S-0Abc2GhV4",
        alt: "Vidéo youtube TED consulting - Tableau Tricks with TED - Go to action UR",
        img: UrlAction,
    },
    {
        id: 22,
        type: videoTypes.TABLEAUSOFTWARE,
        link: "https://www.youtube.com/embed/LHSqlod9bMM",
        title: "Tableau Tricks with TED - Date granularity",
        href: "https://youtu.be/LHSqlod9bMM",
        alt: "Vidéo youtube TED consulting - Tableau Tricks with TED - Date granularity",
        img: DateGranularity,
    },
    // {
    //     id: 23,
    //     type: videoTypes.TABLEAUSOFTWARE,
    //     link: "https://www.youtube.com/embed/RgN2vcu8vwM",
    //     title: "Tableau Tricks with TED - Up Down Arrows",
    // },
    // {
    //     id: 24,
    //     type: videoTypes.TABLEAUSOFTWARE,
    //     link: "https://www.youtube.com/embed/2ypzyxMNJ4Q",
    //     title: "Tableau Tricks with TED - Highlighting Max and Min",
    // },
    // {
    //     id: 25,
    //     type: videoTypes.TABLEAUSOFTWARE,
    //     link: "https://www.youtube.com/embed/ukCuc8voUvk",
    //     title: "Tableau Tricks with TED - Filtering Between Dates",
    // },
    // {
    //     id: 26,
    //     type: videoTypes.TABLEAUSOFTWARE,
    //     link: "https://www.youtube.com/embed/c4aROOWj_q0",
    //     title: "Tableau Tricks with TED - 100% Stacked Bar Chart",
    // },
    // {
    //     id: 27,
    //     type: videoTypes.TABLEAUSOFTWARE,
    //     link: "https://www.youtube.com/embed/R5GyjZ6YAOc",
    //     title: "Tableau Tricks with TED - Map with Pie Charts",
    // },
    // {
    //     id: 28,
    //     type: videoTypes.TABLEAUSOFTWARE,
    //     link: "https://www.youtube.com/embed/OetmzZ89b64",
    //     title: "Tableau Tricks with TED - Lollipop Chart",
    // },
    // {
    //     id: 29,
    //     type: videoTypes.TABLEAUSOFTWARE,
    //     link: "https://www.youtube.com/embed/uxaMQ_Jfvuc",
    //     title: "Tableau Tricks with TED - Create a Calendar",
    // },
    // {
    //     id: 30,
    //     type: videoTypes.TABLEAUSOFTWARE,
    //     link: "https://www.youtube.com/embed/DWi4Dcvq1XM",
    //     title: "Tableau Tricks with TED - Pareto Chart",
    // },
    // {
    //     id: 31,
    //     type: videoTypes.TABLEAUSOFTWARE,
    //     link: "https://www.youtube.com/embed/qhoh6fFy6b4",
    //     title: "Tableau Tricks with TED - Rounded Bar",
    // },
    // {
    //     id: 31,
    //     type: videoTypes.TABLEAUSOFTWARE,
    //     link: "https://www.youtube.com/embed/49mKx4CWseM",
    //     title: "Tableau Tricks with TED - Donut Chart 2",
    // },
    {
        id: 31,
        type: videoTypes.WEBINAR,
        link: "https://www.youtube.com/embed/iPPNhhfgGXc",
        title: "Webinar RPA : Comment automatiser en garantissant Sécurité et Conformité SI ?",
        href: "https://youtu.be/iPPNhhfgGXc",
        alt: "Vidéo youtube TED consulting - Webinar RPA : Comment automatiser en garantissant Sécurité et Conformité SI ?",
        img: WebinarAlmond,
    },


]

