import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// ----- Component -----
import Loader from '../tools/LoaderComponent'

// ----- Helper -----
import { unescapeHtml } from '../../services/helpers/unescapeHtml'


export default function Post({ post, index, currentPage }) {
    const navigate = useNavigate()

    const [date, setDate] = useState("")
    const [title, setTitle] = useState("")

    useEffect(() => {
        if (post.content.rendered) {
            let description = document.getElementById('post-description-' + post?.id);
            description.innerHTML = post?.excerpt?.rendered;

            const wpm = 250;
            const words = post?.content?.rendered.trim().split(/\s+/).length;
            const time = Math.ceil(words / wpm);
            document.getElementById("post-reading-time-" + post?.id).innerText = time + " min de lecture";
        }

        if (post.title) {
            setTitle(unescapeHtml(post.title.rendered))
        }
        if (post.date) {
            const datePublished = new Date(post.date)
            setDate(datePublished.toLocaleDateString("fr"))
        }
        console.log(post, title)
        // eslint-disable-next-line
    }, [post, currentPage])


    return (
        <article key={index} onClick={() => navigate("/blog/" + post?.slug)} className="article-blog">

            {post.medias[0] !== "undefined" ? <img src={post?.medias[0]?.source_url} alt={post?.medias[0]?.alt_text} /> : <Loader />}

            <div className="post-content">

                <h2 className="post-title">{title && title}</h2>
                <p className="post-description" id={"post-description-" + post?.id}></p>
                <p className="post-read-more">Lire plus</p>

                <hr />

                <div className="post-footer">
                    <p className="post-date">{date && date}</p>
                    <p className="post-reading-time" id={"post-reading-time-" + post?.id}></p>
                </div>

            </div>
        </article>
    )
}
