// ----- Images ----- 
import Diagram from '../../../../images/solutions/tailorMade/diagram.png';
import Statistics from '../../../../images/solutions/tailorMade/statistics.png';

export const solutionDevOps = [
    {
        id: 1,
        title: "Consulting",
        descriptions: [
            {
                id: 1,
                text: "Pilotage de projet SCRUM, AGILE pour le suivi des actions et des incidents",
            },
            {
                id: 2,
                text: "Analyse des solutions et Benchmark",
            },
            {
                id: 3,
                text: "Analyse d'impact et de transformation du schéma directeur DevOps",
            },
        ],
        img: Diagram,
        alt: "Deux personnes qui regardent des statistiques sur une tablette mobile",
    },
    {
        id: 2,
        title: "Intégration",
        descriptions: [
            {
                id: 5,
                text: "Création et conception de programmes, algorithmes, applications.",
            },
            {
                id: 6,
                text: "Exploitation, Orchestration et planification des programmes, algorithmes, applications.",
            },
            {
                id: 7,
                text: "Rédaction de la documentation du code",
            },
            {
                id: 8,
                text: "Supervision et Tests des versions logicielles et des programmes dévloppés",
            },
        ],
        img: Statistics,
        alt: "De la Dataviz sur une tablette mobile",
    },
]