import React, { useState, useEffect } from 'react'

// ----- Services -----
import { prestations } from '../../services/constants/components/prestations'

export default function Prestations({ ourPrestationsRef, techno }) {
    const [ourPrestations, setOurPrestations] = useState([])

    useEffect(() => {
        prestations.forEach(prestation => {
            if (prestation.type === techno) {
                setOurPrestations(prestation.prestations)
            }
        });
        // eslint-disable-next-line
    }, [prestations])

    return (
        <div ref={ourPrestationsRef} className="component" id="prestations" >

            <h2>Nos prestations</h2>

            <div className="prestations">

                {ourPrestations.map((prest, index) =>
                    <div className="prestation" key={index}>
                        <div className="prestation-image">
                            <img src={prest.icon} alt={prest.alt} />
                        </div>
                        <p className="prestation-description">{prest.description}</p>
                        <div className="prestation-title">
                            <p>{prest.title}</p>
                        </div>
                    </div>
                )}

            </div>
        </div>
    )
}
