import React from 'react'

export default function CourseByTool({ detailsCourse, index }) {
    return (
        <div className="partner" key={index}>
            <div className="image-container">
                <img src={detailsCourse?.image} alt={detailsCourse.alt} className={`${detailsCourse.size === "xl" ? "xl-logo" : detailsCourse.size === "xs" ? "xs-logo" : "md-logo"}`}/>
            </div>
            <h3>{detailsCourse?.title}</h3>
            <a href={"https://calendar.aliz.io/calendar/ted-consulting/decouvrir-la-rpa-ted"} target="_blank" rel="noopener noreferrer" className="btn-default btn-green">En savoir plus</a>
        </div>
    )
}
