import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'

// ----- Images -----
import HomeIllustration from '../../images/home.svg'
import Icon from '../../images/icons/arrow-down.svg'

// ----- Services -----
import { revealText } from '../../services/helpers/revealText'
import { animationDirection } from '../../services/constants/animationDirection'


// ----- Component ----- 
import ContactUs from '../ContactUs'

export default function Presentation() {


    const leftSide = useRef()
    const rightSide = useRef()
    const firstPage = useRef()


    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        revealText(leftSide.current, animationDirection.TOLEFT, 0, 1)
    }, [])


    return (
        <div className="page container-centered reverse" ref={firstPage} id="firstBlock-presentation">

            <div className="illustration-side" ref={rightSide}>
                <img src={HomeIllustration} alt="Consulting RPA et Data France" />
            </div>

            <div className="one-side" ref={leftSide}>
                <div className="reveal-text">
                    <h1 className="title">Nous transformons, digitalisons et automatisons les processus métiers des entreprises</h1>
                    <p className="description">
                        Nous robotisons vos processus métiers avec la techonologie de <b>RPA</b> et rendons plus intelligents les robots avec <b>l’Intelligence Artificielle</b>.
                        <br/><br/>
                        Nous automatisons la génération du reporting grâce aux outils de <b>Data visualisation</b> et nous
                        optimisons les flux de données grâce à nos compétences Data et <b>Business Intelligence</b>
                    </p>
                    <button className="btn-default btn-orange" variant="primary" onClick={handleShow}>En savoir plus</button>
                </div>
            </div>



            <Link id="scroll-arrow" to={'/#nos-clients'}>
                <img src={Icon} alt="flèche vers le bas" />
            </Link>

            <ContactUs show={show} setShow={setShow}/>

        </div>
    )
}
