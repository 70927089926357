import React from 'react'
import { Link } from 'react-router-dom'


// ----- Constants -----
import { solutions } from '../../../services/constants/components/solutions'

export default function Solutions() {
    return (
        <>
            <div className="list-item-dropdown">
                <div className="item-dropdown">
                    {solutions && solutions.map((solution, index) =>
                        <Link to={solution.link} key={index}><img src={solution.icon} alt={solution.iconAlt} />{solution.title}</Link>
                    )}
                </div>
            </div>
        </>
    )
}
