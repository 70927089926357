import React from 'react'

export default function Service({ title, descriptions, img, alt, id }) {

    return (
        <section className="service-tailor-made" key={id}>
            <div className="service-image">
                <img src={img} alt={alt} />
            </div>
            <h3 className="service-title">{title}</h3>
            <div className="services-description">
                <ul>
                {descriptions && descriptions.map((description) =>
                    <li className="description">
                       {description.text}
                    </li>
                )}
                </ul>
            </div>
        </section>
    )
}
