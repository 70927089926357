// ----- Images -----
import FinanceJob from '../../images/project/Finance.png';
import RhJob from '../../images/project/RH.png';
import ItJob from '../../images/project/IT.png';
import SupplyChainJob from '../../images/project/Supply_Chain.png';
import SavJob from '../../images/project/Service_client.png';

// ----- Services -----
import { jobs } from '../constants/jobs';


export const setIconByJob = (projectJob, setIcon, setName) => {
    switch (projectJob) {
        case jobs.FINANCE:
            setIcon(FinanceJob)
            setName("Finance")
            break;
        case jobs.RH:
            setIcon(RhJob)
            setName("RH")
            break;
        case jobs.IT:
            setIcon(ItJob)
            setName("IT")
            break;
        case jobs.SUPPLYCHAIN:
            setIcon(SupplyChainJob)
            setName("Supply Chain")
            break;
        case jobs.SAV:
            setIcon(SavJob)
            setName("SAV")
            break;
        default:
            break;
    }
}