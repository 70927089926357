import React from 'react'

export default function Presentation({ course }) {
    return (
        <>
            <div className="component">
                <img src={course?.image} alt={course?.alt} className={`${course.size === "xl" ? "xl-logo-presentation" : course.size === "xs" ? "xs-logo-presentation" : "md-logo-presentation"}`} />
            </div>

            <section className="presentation">
                <h1>Nos formations {course?.title}</h1>
                <p className="subtitle">Nous proposons différentes formations dans le but de vous accompagner au mieux et surtout au plus proche de votre business.</p>
                <p className="subtitle">{course.description && course.description}</p>

            </section>
        </>
    )
}
