// ----- Images ----- 
import Diagram from '../../../../images/solutions/tailorMade/diagram.png';
import Statistics from '../../../../images/solutions/tailorMade/statistics.png';
import Chat from '../../../../images/solutions/tailorMade/chat.png';

export const solutionBpm = [
    {
        id: 1,
        title: "Consulting",
        descriptions: [
            {
                id: 1,
                text: "Atelier Design Thinking pour la préparation au Process Mining",
            },
            {
                id: 2,
                text: "Atelier Design Thinking pour la préparation au Task Mining",
            },
            {
                id: 3,
                text: "Stratégie de déploiement du Business Process Management",
            },
            {
                id: 4,
                text: "Benchmark et recommandations sur le choix des outils BPM",
            },
        ],
        img: Diagram,
        alt: "Deux personnes qui regardent des statistiques sur une tablette mobile",
    },
    {
        id: 2,
        title: "Intégration",
        descriptions: [
            {
                id: 5,
                text: "Réalisation de POC & Pilotes",
            },
            {
                id: 6,
                text: "Analyse de logs des applicatifs utilisés par vos processus (process mining)",
            },
            {
                id: 7,
                text: "Identification et aggrégation des workflows et tâches de vos collaborateurs (task mining)",
            },
            {
                id: 8,
                text: "Mise en place du plan d'action à mener dans un programme de Business Process Management",
            },
        ],
        img: Statistics,
        alt: "De la Dataviz sur une tablette mobile",
    },
    {
        id: 3,
        title: "Formation et conduite du changement",
        descriptions: [
            {
                id: 9,
                text: "Formation Process Mining",
            },
            {
                id: 10,
                text: "Formation Task Mining",
            },
            {
                id: 11,
                text: "Mentoring & Coaching (Digital Factory, IT, C-levels)",
            },
            {
                id: 12,
                text: "Formation Technique & Administration",
            },
        ],
        img: Chat,
        alt: "Dataviz avec de multiple options au bout d'un doigt",
    },
]