import React from 'react'

export default function Presentation() {
    return (
        <section className="presentation component">
            <div>
                <h1>Nos partenaires</h1>
                <p className="subtitle">
                    Nous sommes partenaires et revendeurs de ces solutions, nous bénéficions de tarifs avantageux.
                    <br />Si vous le souhaitez, contactez nous pour un devis
                </p>
            </div>
        </section>
    )
}
