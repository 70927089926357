import React from 'react'

// ----- Images ----- 
import Logo from '../../images/logos/logo-blue.png';

//  ----- Components  -----
// import Cursor from './Cursor';

export default function Loader() {


    return (
        <div id="loader">

            {/* <Cursor /> */}
            <div className="">
                <img src={Logo} alt="TED consulting, Technology Empowers Data" />
                <div className="reveal-text">
                    <p>Technology Empowers Data</p>
                </div>
            </div>
        </div >
    )
}
