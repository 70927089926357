import React from 'react'

// ----- Images -----
import FormationIllustration from '../../images/courses/formation.svg'

export default function Presentation() {
    return (
        <section className="presentation component">


            <div className="text-presentation">
                <h1>Nos formations par éditeurs</h1>
                <p className="subtitle">Nous proposons différentes formations dans le but de vous accompagner au mieux et surtout au plus proche de votre business.</p>
            </div>

            <img src={FormationIllustration} alt="Formations RPA & Data France" />

        </section>
    )
}
