import React from 'react'
import Modal from 'react-bootstrap/Modal';


export default function ContactUs({ show, setShow }) {

    const handleClose = () => setShow(false);

    return (
        <Modal show={show} onHide={handleClose} className="modal-contact">
            <iframe id="frame-contact" src="https://forms.monday.com/forms/embed/0e3079d3967c0efb4fa5ab761439bd03?r=use1" height="1000" title="formulaire de contact TED consulting - HyperAutomation" ></iframe>
        </Modal>
    )
}
