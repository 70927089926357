import React from 'react'

// ----- Images -----
import InnovationTaxCredit from '../../images/solutions/innovation-tax-credit.png'


export default function InnovationLab() {
    return (
        <div className="component">
            <h2>Ted, votre lab d'innovation !</h2>
            <p className="subtitle">
                Notre société est éligible au Crédit d'impôt innovation (CII). Nous portons les projets d'innovation de nos clients et
                nous allons chercher des subventions afin de couvrir une partie des investissements.
                Contactez-nous si vous souhaitez en savoir plus.
            </p>
            <div className="my-5">
                <img src={InnovationTaxCredit} alt={"Agrément CII Crédit impôt innnovation"} />
            </div>
        </div>
    )
}
