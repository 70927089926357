import React from 'react'
import { Link } from 'react-router-dom'

// ----- Images -----
import ContextIllu from '../../images/project/contexte.png'
import ManualProcessIllu from '../../images/project/Processus_Manuel.png'
import AutomatedProcessIllu from '../../images/project/Processus_Automated.png'

export default function ContextAndProcess({ project }) {

    return (
        <section >


            {/* ------- Context ------- */}
            <div className="component container-centered project-context not-reverse">
                <div className="image-container">
                    <img src={ContextIllu} alt={"Analyse de processus par nos experts"} />
                </div>

                <div className="context-container">
                    <h2>Le contexte</h2>
                    <p className="description">{project?.description}</p>
                    <h3>Les "pains points"</h3>
                    <div className={`painPoint-container ${!project.available ? "blur-component" : ""}`}>
                        {project.painPoints && project?.painPoints.map((painPoint, key) =>
                            <div className="painPoint" key={key}>
                                <p>{painPoint}</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>


            {/* ------- Process ------- */}
            <div id="process-container">

                {/* -------- Project not available ---- */}
                {!project.available &&
                    <div id="project-not-available">
                        <div>
                            <div className="not-available-container">
                                <p>En cours de rédaction...</p>
                                <Link to={"/nos-projets"} className="btn-default btn-white">
                                    Revenir en arrière
                                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M7.91988 2H16.0899C19.6199 2 21.9999 4.271 21.9999 7.66V16.33C21.9999 19.72 19.6199 22 16.0899 22H7.91988C4.37988 22 1.99988 19.72 1.99988 16.33V7.66C1.99988 4.271 4.37988 2 7.91988 2ZM9.72988 12.75H16.0799C16.4999 12.75 16.8299 12.41 16.8299 12C16.8299 11.58 16.4999 11.25 16.0799 11.25H9.72988L12.2099 8.78C12.3499 8.64 12.4299 8.44 12.4299 8.25C12.4299 8.061 12.3499 7.87 12.2099 7.72C11.9199 7.43 11.4399 7.43 11.1499 7.72L7.37988 11.47C7.09988 11.75 7.09988 12.25 7.37988 12.53L11.1499 16.28C11.4399 16.57 11.9199 16.57 12.2099 16.28C12.4999 15.98 12.4999 15.51 12.2099 15.21L9.72988 12.75Z" />
                                    </svg>
                                </Link>
                            </div>
                        </div>
                    </div>
                }
                <div className={`component container-centered project-context reverse ${!project.available ? "blur-component" : ""}`}>
                    <div className="image-container">
                        <img src={ManualProcessIllu} alt={"Analyse des processus manuel par notre équipe d'expert"} />
                    </div>

                    <div className="context-container">
                        <h2>Processus manuel</h2>
                        <ul>
                            {project.manualProcess && project?.manualProcess.map((step, key) =>
                                <li key={key}>{step}</li>
                            )}
                        </ul>
                    </div>
                </div>


                <div className={`component container-centered project-context not-reverse ${!project.available ? "blur-component" : ""}`}>

                    <div className="image-container">
                        <img src={AutomatedProcessIllu} alt={"Processus après automatisation effectué par nos équipes"} />
                    </div>

                    <div className="context-container">
                        <h2>Processus après automatisation</h2>
                        <ul>
                            {project.automatedProcess && project?.automatedProcess.map((step, key) =>
                                <li key={key}>{step}</li>
                            )}
                        </ul>
                    </div>

                </div>

            </div>

        </section>
    )
}
