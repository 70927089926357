import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'


// ----- Packages -----
import { Helmet, HelmetProvider } from 'react-helmet-async';

// ----- Component -----
import Presentation from '../components/course/Presentation'
import CoursesByTools from '../components/course/CoursesByTools'
import CarouselCourses from '../components/course/CarouselCourses'

// ----- Constants -----
import { courses } from '../services/constants/components/courses';


export default function OneCourse() {
    const [course, setCourse] = useState([])
    const params = useParams();


    useEffect(() => {
        setCourse(courses.filter(course => course.slug === params.slug)[0])
    }, [params])

    return (
        <HelmetProvider>

            <Helmet prioritizeSeoTags>
                <title>{course && "Formation - " + course.title}</title>
                <meta name="description" content={course.description && course.description} />
                <meta property="og:title" content={course.title && "Formation - " + course.title} />
                <meta property="og:description" content={course.description && course.description} />
            </Helmet>

            <div className="page" id="oneCoursePage">

                <Presentation course={course} />

                <CoursesByTools course={course} />

                <CarouselCourses course={course} />

            </div>
        </HelmetProvider>
    )
}
