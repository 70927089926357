import React from 'react'

// ----- Constants -----
import { sectorsLabel, jobsLabel } from '../../../services/constants/components/labelFilter'

export default function Projects() {
    return (
        <>
            <div className="list-item-dropdown">
                <div className="item-dropdown">
                    <div className="list-resources">

                        <div className="item-resources">
                            <b>Par métiers</b>
                            {jobsLabel && jobsLabel.map((job, index) =>
                                <div className="jobAndSector" key={index}>
                                    <img src={job.icon} alt="icone par métier" />
                                    <a href={"/nos-projets?jobs=" + job.value}>{job.title} </a>
                                </div>
                            )}
                        </div>

                        <div className="item-resources">
                            <b>Par secteurs</b>
                            {sectorsLabel && sectorsLabel.map((sector, index) =>
                                <div className="jobAndSector" key={index}>
                                    <img src={sector.icon} alt="icone par secteur" />
                                    <a href={"/nos-projets?sectors=" + sector.value}>{sector.title} </a>
                                </div>
                            )}
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}
