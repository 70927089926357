import React from 'react'

export default function Presentation() {
    return (
        <section className="presentation component">

            <div className="text-presentation">
                <div id="title-banner">
                    <h1>Blog</h1>
                    <p>RPA, Data, Process, DevOps, IA retrouvez tous les sujets de l'Hyper Automation dans notre blog</p>
                </div>
                <p className="subtitle">Nos dernières publications: </p>
                <div className='hr'></div>
            </div>
        </section>
    )
}
