import React from 'react'


// ----- Images -----
import RosaceGreen from '../../images/logos/rosace-green.png'
import RosaceCorporate from '../../images/rosace-corporate.png'
import RosaceHyperAutomation from '../../images/rosace-hyperautomation.png'

export default function Poles() {
    return (
        <div id="poles" className="">
            <div className="title">
                <img className="rosace" src={RosaceGreen} alt={"rosace verte du logo TED consulting"} />
                <h2>Les Pôles</h2>
            </div>

            <div className="container-centered">

                <div className="pole-container">
                    <h3>Hyper Automation</h3>

                    <img className="bg-rosace" src={RosaceHyperAutomation} alt="rosace verte TED consulting" />
                </div>

                <div className="pole-container">
                    <h3>Corporate</h3>

                    <img className="bg-rosace" src={RosaceCorporate} alt="rosace verte TED consulting" />
                </div>

            </div>


        </div>
    )
}
