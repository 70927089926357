import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal';

// ----- Packages -----
import emailjs, { init } from 'emailjs-com';
init("user_miA3ipyhZVGHs8J5Myyb1");

export default function ContactUsROI({ show, setShow, reportFile, project }) {
    const handleClose = () => setShow(false);

    const [firstname, setFirstname] = useState("")
    const [lastname, setLastname] = useState("")
    const [email, setEmail] = useState("")
    const [response, setResponse] = useState(false)
    const [errors, setErrors] = useState(false)

    // code monday : 0INcfa24wHT0DxQU
    // alix ou mathias
    // eslint-disable-next-line
    const data = {
        "firstname": firstname,
        "lastname": lastname,
        "email": email,
        "usecase": project.title,
        "id": project.id
    }

    const handleContact = () => {
        if (firstname === "" || lastname === "" || email === "") {
            setErrors("Merci de remplir tous les champs")
        } else {
            setErrors(false)
            setResponse(true)
        }
    }

    const downloadFile = (filePath) => {
        var link = document.createElement('a');
        link.href = filePath;
        link.download = filePath.substr(filePath.lastIndexOf('/') + 1);
        link.click();
    }

    useEffect(() => {
        if (response) {
      
            emailjs.send('ted-use-case', 'template_tdxi0cr', data)
            .then(function (response) {
                // alert('SUCCESS!', response.status, response.text);
            }, function (error) {
                // alert('FAILED...', error);
            });
            alert('Merci de votre confiance, un membre de notre équipe va vous recontacter dans les meilleurs délais')
            downloadFile(reportFile)
            handleClose()
            // setResponse(false)
        }
        // eslint-disable-next-line
    }, [response])



    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Télécharger le Use Cases</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form>
                    <div className="double-input">
                        <div>
                            <label htmlFor="firstname">Prénom</label>
                            <input
                                type="text"
                                id="firstname"
                                name="firstname"
                                className="form-control"
                                required
                                value={firstname}
                                onChange={(e) => setFirstname(e.target.value)}
                            />
                        </div>
                        <div>
                            <label htmlFor="lastname">Nom</label>
                            <input
                                type="text"
                                id="lastname"
                                name="lastname"
                                className="form-control"
                                required
                                value={lastname}
                                onChange={(e) => setLastname(e.target.value)}
                            />
                        </div>
                    </div>

                    <div>
                        <label htmlFor="email">Courriel</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            className="form-control"
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        {errors && <span className="text-danger">{errors}</span>}
                    </div>

                </form>
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
                <button className="btn-default btn-orange" onClick={handleContact}>Télécharger</button>
            </Modal.Footer>
        </Modal>
    )
}
