import React, { useState, useEffect } from 'react'

// ----- Packages ----- 
import { v4 as uuidv4 } from 'uuid';

export default function LabelTechno({ label, selectedJobs, setSelectedJobs, isActiveJobs, index }) {
  const [isActive, setIsActive] = useState(false)


  // ----- If the selector corresponds to the label: I activate it -----
  useEffect(() => {
    if (selectedJobs.length > 0) {
      selectedJobs.forEach(element => {
        if (element === label.value) {
          setIsActive(true)
        }
      });
    }
  }, [selectedJobs, label.value])

  // ----- If the label is active, I add it to the filters, otherwise I remove it -----
  useEffect(() => {
    if (isActive && !selectedJobs.includes(label.value)) {
      setSelectedJobs(arr => [...arr, label.value]);
    } else if (!isActive) {
      setSelectedJobs(selectedJobs.filter(item => item !== label.value))
    }
    // eslint-disable-next-line
  }, [isActive])


  // ----- If the 'all' filter is selected, I activate the label -----
  useEffect(() => {
    if (!isActiveJobs) {
      setIsActive(false)
    }
  }, [isActiveJobs])


  return (
    <button
      key={index}
      id={uuidv4()}
      value={label.value}
      className={`btn-label ${isActive ? "is-active" : ""}`}
      onClick={() => setIsActive(isActive ? false : true)}
    >
      {label.title}
    </button>

  )
}
