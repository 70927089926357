export const redirectURI = (locationPathname, navigate) => {

    if (locationPathname.match(/glossaire-des-termes-de-la-rpa-2021/)) {
       navigate('/blog/glossaire-des-termes-de-la-rpa-2023')
    }else if(locationPathname.match(/3-tableaux-essentiels-démarquer-concurrence/)){
       navigate('/nos-solutions/bi')
    }else if(locationPathname.match(/tout-savoir-sur-la-rpa/)){
       navigate('/blog/tout-ce-quil-faut-savoir-sur-la-rpa')
    }else if(locationPathname.match(/automatisation-processus-comptable/) || locationPathname.match(/master-data-fournisseurs/)|| locationPathname.match(/processus-demandes-investissements/)){
       navigate('/blog')
    }else if(locationPathname.match(/process-task-mining-est-ce-la-fin-du-m%c3%a9tier-de-business-analyst-rpa/)){
       navigate('/blog/process-task-mining-la-fin-du-metier-de-business-analyst')
    }else if(locationPathname.match(/contact/) || locationPathname.match(/rpa-master-data-finance/)){
       navigate('/')
    }else if(locationPathname.match(/que-faisons-nous/)){
       navigate('/qui-sommes-nous')
    }else if(locationPathname.match(/gouvernance-donnees/)){
       navigate('/nos-solutions/devops')
    }else if(locationPathname.match(/rpa-assistant-virtuel-assistant-digital-un-concept-avec-des-nuances/)){
       navigate('/nos-solutions/rpa')
    }
}
