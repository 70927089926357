import React, { useState, useEffect } from 'react'

// ----- Images -----
import RosaceGreen from '../../images/logos/rosace-green.png'
import RosaceBlue from '../../images/logos/rosace-blue.png'
import RosaceOrange from '../../images/logos/rosace-orange.png'

// ----- Constants -----
import { partners } from '../../services/constants/components/partners';
import { technos } from '../../services/constants/technos';

// ----- Component -----
import Partner from './Partner'

export default function AllPartners() {

    const [partnersRpa, setPartnersRpa] = useState([])
    const [partnersBpm, setPartnersBpm] = useState([])
    const [partnersBi, setPartnersBi] = useState([])
    const [partnersAdvice, setPartnersAdvice] = useState([])

    useEffect(() => {
        setPartnersRpa([])
        setPartnersBpm([])
        setPartnersBi([])
        setPartnersAdvice([]
        )
        partners.forEach(partner => {
            if (partner.title) {
                if (partner.technos.includes(technos.RPA)) {
                    setPartnersRpa(arr => [...arr, partner])
                }
                if (partner.technos.includes(technos.BPM)) {
                    setPartnersBpm(arr => [...arr, partner])
                }
                if (partner.technos.includes(technos.BI)) {
                    setPartnersBi(arr => [...arr, partner])
                }
                if (partner.type) {
                    setPartnersAdvice(arr => [...arr, partner])
                }
            }
        });
        // eslint-disable-next-line
    }, [partners])


    return (
        <>
            <section>
                <div className="title-container">
                    <img src={RosaceGreen} className="logo" alt="rosace verte du logo TED" />
                    <h2>Automation Platform & solutions RPA</h2>
                </div>

                <div className="partners-container container-centered">
                    {
                        partnersRpa && partnersRpa.map((partner) =>
                            <Partner partner={partner} />
                        )
                    }
                </div>
            </section>

            <section className="component bg-lightblue">
                <div className="title-container">
                    <img src={RosaceGreen} className="logo" alt="rosace verte du logo TED" />
                    <h2>Process Mining & Task Mining</h2>
                </div>

                <div className="partners-container container-centered">
                    {
                        partnersBpm && partnersBpm.map((partner) =>
                            <Partner partner={partner} />
                        )
                    }
                </div>
            </section>


            <section className="component">
                <div className="title-container">
                    <img src={RosaceBlue} className="logo" alt="rosace verte du logo TED" />
                    <h2>Business Intelligence & Data Viz </h2>
                </div>

                <div className="partners-container container-centered">
                    {
                        partnersBi && partnersBi.map((partner) =>
                            <Partner partner={partner} />
                        )
                    }
                </div>
            </section>

            <section className="component bg-lightgreen">
                <div className="title-container">
                    <img src={RosaceOrange} className="logo" alt="rosace verte du logo TED" />
                    <h2>Partenaires Conseil</h2>
                </div>

                <div className="partners-container container-centered">
                    {
                        partnersAdvice && partnersAdvice.map((partner) =>
                            <Partner partner={partner} />
                        )
                    }
                </div>
            </section></>
    )
}
