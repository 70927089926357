import React, {  useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { redirectURI } from '../../services/constants/redirectURI'

export default function Location({setLocation}) {
    const location = useLocation()
    const navigate = useNavigate()
    
    useEffect(() => {
        setLocation(location)
        if(location.pathname){
            redirectURI(location.pathname, navigate) 
        }
        // eslint-disable-next-line
    }, [location])

    return (
        <div>

        </div>
    )
}