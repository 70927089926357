import React, { useEffect, useState } from 'react'

// ----- Component -----
import Presentation from '../components/blog/Presentation'
import Post from '../components/blog/Post'
import Loader from '../components/tools/LoaderComponent'

// ----- Packages -----
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ReactPaginate from "react-paginate";

// ----- Services -----
import { getPostsBlog, getMedias } from '../services/axios/blog';

export default function Blog() {
  const [loading, setLoading] = useState("")
  const [posts, setPosts] = useState([])
  const [allMedias, setAllMedias] = useState([])
  const [postsWithMedias, setPostsWithMedias] = useState([])

  // ----- Paging -----
  const [currentPage, setCurrentPage] = useState(0)

  useEffect(() => {
    setLoading(true)
    getPostsBlog(setPosts)
    getMedias(setAllMedias)
  }, [])


  useEffect(() => {
    if (posts.length > 0 && allMedias.length > 0) {
      setPostsWithMedias(posts.map(post => {
        const medias = allMedias.filter(media => media.post === post.id);

        return { ...post, medias };
      }));

      setLoading(false)
    }
    // eslint-disable-next-line
  }, [allMedias, posts])

  const PER_PAGE = 4;
  const offset = currentPage * PER_PAGE;
  const pageCount = Math.ceil(postsWithMedias.length / PER_PAGE);

  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }


  return (
    <HelmetProvider>

      <Helmet prioritizeSeoTags>
        <title>Blog</title>
        <meta name="description" content="Le blog Ted - Découvrez nos derniers articles sur la RPA, BPM, DATA, IA et DevOps." />
        <meta property="og:title" content="Blog" />
        <meta property="og:description" content="Le blog Ted - Découvrez nos derniers articles sur la RPA, BPM, DATA, IA et DevOps" />
      </Helmet>

      <div className="page" id="blogPage">

        <Presentation />


        <div className="posts-container">
          {loading && <Loader />}


          {postsWithMedias && postsWithMedias.slice(offset, offset + PER_PAGE)
            .map((post, index) => {
              return <Post post={post} key={index} currentPage={currentPage} />
            })
          }
        </div>


        {(() => {
          if (posts.length > PER_PAGE) {
            return <div className="container-centered">
              <ReactPaginate
                previousLabel={"←"}
                nextLabel={"→"}
                pageCount={pageCount}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                previousLinkClassName={"pagination__link"}
                nextLinkClassName={"pagination__link"}
                disabledClassName={"pagination__link--disabled"}
                activeClassName={"pagination__link--active"}
              />
            </div>
          }
        })()}

      </div>
    </HelmetProvider>
  )
}
