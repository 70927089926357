import React from 'react'

// ----- Components -----
import Service from './Service'

// ----- Services -----
import { scrollReveal } from '../../../services/helpers/revealText'

// ----- Constants -----
import { technos } from '../../../services/constants/technos'
import { solutionRpa } from '../../../services/constants/components/solutions/solutionRpa'
import { solutionBi } from '../../../services/constants/components/solutions/solutionBi'
import { solutionIa } from '../../../services/constants/components/solutions/solutionIa'
import { solutionBpm } from '../../../services/constants/components/solutions/solutionBpm'
import { solutionDevOps } from '../../../services/constants/components/solutions/solutionDevOps'


export default function OurSolutions({ ourServicesRef, techno, title }) {

    // ----- Solution animation -----
    scrollReveal('#services section', true)

    return (
        <div ref={ourServicesRef} className="component " id="services" >

            <h2>Une offre {title} sur-mesure</h2>

            {/* Pas ouf, à améliorer (tout inclure dans la même constante solutions feraient un enorme fichier illisible) */}
            <div className="services-tailor-made">

                {(() => {
                    switch (techno) {
                        case technos.RPA:
                            return solutionRpa.map((solution, index) =>
                                <Service
                                    id={index}
                                    title={solution.title}
                                    descriptions={solution.descriptions}
                                    reverse={solution.reverse}
                                    img={solution.img}
                                    alt={solution.alt}
                                />
                            );
                        case technos.IA:
                            return solutionIa.map((solution, index) =>
                                <Service
                                    id={index}
                                    title={solution.title}
                                    descriptions={solution.descriptions}
                                    reverse={solution.reverse}
                                    img={solution.img}
                                    alt={solution.alt}
                                />
                            );
                        case technos.BI:
                            return solutionBi.map((solution, index) =>
                                <Service
                                    id={index}
                                    title={solution.title}
                                    descriptions={solution.descriptions}
                                    reverse={solution.reverse}
                                    img={solution.img}
                                    alt={solution.alt}
                                />
                            );
                        case technos.BPM:
                            return solutionBpm.map((solution, index) =>
                                <Service
                                    id={index}
                                    title={solution.title}
                                    descriptions={solution.descriptions}
                                    reverse={solution.reverse}
                                    img={solution.img}
                                    alt={solution.alt}
                                />
                            );
                        case technos.DEVOPS:
                            return solutionDevOps.map((solution, index) =>
                                <Service
                                    id={index}
                                    title={solution.title}
                                    descriptions={solution.descriptions}
                                    reverse={solution.reverse}
                                    img={solution.img}
                                    alt={solution.alt}
                                />
                            );
                        default:
                            break;
                    }

                })()}
            </div>

        </div>
    )
}
