import React, { useState, useEffect, useMemo } from 'react'

// ----- Packages -----
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import gsap from 'gsap';

// ----- Components -----
import ContactUsROI from '../ContactUsROI'


export default function Tools({ project }) {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);


    const [allTools, setAllTools] = useState([])
    const [count, setCount] = useState(0)


    const loadCarousel = useMemo(() => {
        setAllTools([])
        if (project.tools) {
            project.tools.forEach(tool => {
                setAllTools(arr => [...arr, <img src={tool.image} alt={tool.alt} className={`${tool.size === "xl" ? "xl-logo" : tool.size === "xs" ? "xs-logo" : "md-logo"}`} />]);
            });
        }
        // eslint-disable-next-line
    }, [project.tools]);

    useEffect(() => {
        return loadCarousel
    }, [loadCarousel])


    useEffect(() => {
        if (count === 0) {
            setTimeout(() => {
                gsap.to('.alice-carousel', { css: { width: "101%" }, duration: 0 });
                setCount(1)
            }, 1000);
        }
    }, [count])




    return (
        <div id='projectTools'>
            <div className="component">
                <h2>Les outils utilisés</h2>


                <div className="container-carousel">


                    <AliceCarousel
                        mouseTracking
                        controlsStrategy={'responsive'}
                        items={allTools}
                        infinite={true}
                        responsive={{
                            0: {
                                items: 3,
                            },
                            992: {
                                items: 3,
                            },
                            1200: {
                                items: 4,
                            },
                        }} />
                </div>
            </div>
            <div className="component">
                <h2>Le ROI et les résultats</h2>
                <div className="roiUseCase">
                    <button className="btn-default btn-orange" onClick={handleShow}>Télécharger le rapport complet avec les ROI du client</button>

                    {/*  ---- Popup Contact Us ----- */}
                    <ContactUsROI show={show} setShow={setShow} reportFile={project.reportFile} project={project} />
                </div>
            </div>
        </div>
    )
}
