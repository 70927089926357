import React, { useEffect, useRef } from 'react'

// ----- Components -----
import Presentation from '../components/home/Presentation'
import TrustedUs from '../components/home/TrustedUs'
import OurSolutions from '../components/home/solutions/OurSolutions'
import OurProjects from '../components/projectsCarousel/OurProjects'
// import OpinionsClients from '../components/home/opinions/OpinionsClients'
import VideoBSmart from '../components/VideoBSmart'
import Partners from '../components/PartnersCarousel'


// ----- Packages -----
import { Helmet, HelmetProvider } from 'react-helmet-async';


export default function Home({ location }) {
  const trustedUsRef = useRef()
  const ourProjectsRef = useRef()


  useEffect(() => {
    switch (location.hash) {
      case "#nos-clients":
        trustedUsRef.current.scrollIntoView()
        break;

      default:
        break;
    }
  }, [location])

  return (
    <HelmetProvider>

      <Helmet prioritizeSeoTags>
        <title>Ted consulting - Le spécialiste de l'automatisation</title>
        <meta name="description" content="100% de nos projets concernent les technologies RPA, BPM, DATA et IA. L'automatisation au service de la performance de votre entreprise" />
        <meta property="og:title" content="Ted consulting - Le spécialiste de l'automatisation" />
        <meta property="og:description" content="100% de nos projets concernent les technologies RPA, BPM, DATA et IA. L'automatisation au service de la performance de votre entreprise" />
      </Helmet>

      <div id="home">

        {/* ----- First Page - Presentation TED ----- */}
        <Presentation />

        {/* ----- They trusted us -----*/}
        <TrustedUs trustedUsRef={trustedUsRef} />

        {/* ----- Our Solutions (RPA, BI...) -----*/}
        <OurSolutions />

        {/* ----- Our Projects -----*/}
        <OurProjects technos={false} title={false} ourProjectsRef={ourProjectsRef} favorite={true} />

        {/* ----- Opinions clients -----*/}
        {/* <OpinionsClients /> */}

        {/* ----- Vidéo B Smart -----*/}
        <VideoBSmart />

        {/* ----- Partners -----*/}
        <Partners solution={""} title={""} />

      </div>
    </HelmetProvider>

  )
}
