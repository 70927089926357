import React from 'react'
import { Link } from 'react-router-dom'


export default function Solution({ title, description, link, img, reverse, alt, id, index }) {

    return (
        <section className={`container-centered ${reverse ? "reverse" : " not-reverse"}`} key={index}>
            <div className="one-side solution">
                <img src={img} alt={alt} />
            </div>
            <div className="one-side solution">
                <h3 className="title">{title}</h3>
                <p className="description">
                    {description}
                </p>
                <Link to={link} className="btn-default btn-orange">En savoir plus</Link>
            </div>
        </section>

    )
}
