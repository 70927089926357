// ----- Constants ----- 
import { technos } from '../technos';

// ----- Images ----- 
import Uipath from '../../../images/partners/UiPath-logo.png';
import Winshuttle from '../../../images/partners/winshuttle.png';
import AutomationAnywhere from '../../../images/partners/automation_anywhere.png';
import PowerBi from '../../../images/partners/Power-BI.png';
// import WinshuttleCertif from '../../../images/partners/Winshuttle_Certif.png';
import TableauPartner from '../../../images/partners/Tableau-Partner.png';
import QlikSense from '../../../images/partners/qlik-sense.png';
// import Sap from '../../../images/partners/SAP.png';
import TedLogo from '../../../images/logos/logo-blue.png';
import IqBot from '../../../images/courses/iq-bot.png';
import AutomateEvolve from '../../../images/courses/automate-evolve.png';
import Composer from '../../../images/courses/composer.png';
import PowerBiDesktop from '../../../images/courses/powerbi-desktop.png';
import PowerBiReportServer from '../../../images/courses/powerbi-report-server.png';
import TableauSoftwareDesktop from '../../../images/courses/tableau-software-desktop.png';
import TableauSoftwarePrep from '../../../images/courses/tableau-software-prep.png';
import TableauSoftwareServer from '../../../images/courses/tableau-software-server.png';
import WStudio from '../../../images/courses/w-studio.png';
import SAPBusinessObject from '../../../images/courses/sap-business-object.png';
import SAPBusinessBI4 from '../../../images/courses/sap-business-bi4.png';

// import Uipath from '../../../images/partners/UiPath-logo.png';
// import PowerAutomate from '../../../images/partners/Power-Automate.png';
// import BluePrism from '../../../images/partners/blueprism-logo.png';
// import MicrosoftDynamic from '../../../images/partners/dynamics.png';
// import Oracle from '../../../images/partners/Oracle.png';
// import SalesForce from '../../../images/partners/salesforce-logo.png';
// import Sharepoint from '../../../images/partners/sharepoint.png';
// import SqlServer from '../../../images/partners/sql_server.png';
// import TalendHigh from '../../../images/partners/talend-high.png';
// import Celonis from '../../../images/partners/Celonis.png';
// import TaskMining from '../../../images/partners/taskMining.png';
// import MicrosoftPlatform from '../../../images/partners/microsoft_platform.png';
// import Aymax from '../../../images/partners/aymax.png';
// import Magellan from '../../../images/partners/magellan.png';
// import Almond from '../../../images/partners/almond.png';


// ----- Packages ----- 
import { v4 as uuidv4 } from 'uuid';


export const courses = [
    {
        id: 1,
        slug: "winshuttle",
        technos: [technos.RPA],
        image: Winshuttle,
        alt: "Winshuttle",
        title: "Winshuttle",
        description: "Guidé par un expert certifié, apprenez à maitriser l'outil Winshuttle pour tous vos projets de développement RPA.",
        size: "xs",
        key: uuidv4(),
        courses: [
            {
                title: "développeur RPA avec W Studio",
                image: WStudio,
                alt: "W Studio",
                size: "md",
            },
            {
                title: "développeur RPA avec Winshuttle Composer",
                image: Composer,
                alt: "Winshuttle Composer",
                size: "md",
            },
            {
                title: "développeur RPA avec Automate Evolve",
                image: AutomateEvolve,
                alt: "Winshuttle Composer",
                size: "xl",
            },
        ]
    },

    {
        id: 2,
        slug: "ui-path",
        technos: [technos.RPA],
        image: Uipath,
        alt: "UI Path",
        size: "xl",
        key: uuidv4(),
        title: "UiPath",
        description: "Guidé par un expert certifié, apprenez à maitriser l'outil UiPath Studio pour tous vos projets de développement RPA.",
        courses: [
            {
                title: "développeur RPA avec UiPath Studio",
                image: "",
                alt: "Studio",
                size: "xs",
            },
            {
                title: "développeur RPA avec UiPath Studio X",
                image: "",
                alt: "StudioX",
                size: "xs",
            },
            {
                title: "développeur RPA avec Orchestrator",
                image: "",
                alt: "Orchestrator",
                size: "xs",
            },
            {
                title: "Process & Task Mining",
                image: "",
                alt: "Task Mining & Process Mining",
                size: "xs",
            },
        ]

    },
    {
        id: 3,
        slug: "automation-anywhere",
        technos: [technos.RPA],
        image: AutomationAnywhere,
        alt: "Automation Anywhere",
        title: "Automation Anywhere",
        description: "Guidé par un expert certifié, apprenez à maitriser l'outil Automation Anywhere pour tous vos projets de développement RPA.",
        size: "md",
        key: uuidv4(),
        courses: [
            {
                title: "développeur RPA avec A360 platform",
                image: AutomationAnywhere,
                alt: "développeur RPA avec A360 platform",
                size: "md",
            },
            {
                title: "développeur RPA avec IQ Bot",
                image: IqBot,
                alt: "développeur RPA avec IQ Bot",
                size: "xl",
            },

        ]
    },
    {
        id: 4,
        slug: "power-bi",
        technos: [technos.BI],
        image: PowerBi,
        alt: "Power BI",
        title: "Power BI",
        description: "Guidé par un expert certifié, apprenez à maitriser l'outil Power BI pour tous vos projets de Data Visualisation.",
        size: "xl",
        key: uuidv4(),
        courses: [
            {
                title: "Power BI Desktop ou Pro",
                image: PowerBiDesktop,
                alt: "Power BI Desktop ou Pro",
                size: "xl",
            },
            {
                title: "Power Bi Report Server",
                image: PowerBiReportServer,
                alt: "Power Bi Report Server",
                size: "md",
            },
        ]
    },
    {
        id: 5,
        slug: "tableau-partner",
        technos: [technos.BI],
        image: TableauPartner,
        alt: "Tableau Partner",
        title: "Tableau Software",
        description: "Guidé par un expert certifié, apprenez à maitriser l'outil Tableau Software pour tous vos projets de Data Visualisation.",
        size: "md",
        key: uuidv4(),
        courses: [
            {
                title: "Tableau Desktop",
                image: TableauSoftwareDesktop,
                alt: "Tableau Desktop",
                size: "xl",
            },
            {
                title: "Tableau Prep",
                image: TableauSoftwarePrep,
                alt: "Tableau Prep",
                size: "xl",
            },
            {
                title: "développeur RPA avec Automate Evolve",
                image: TableauSoftwareServer,
                alt: "Automate Evolve",
                size: "md",
            },
        ]
    },
    {
        id: 6,
        slug: "qlik-sense",
        technos: [technos.BI],
        image: QlikSense,
        alt: "Qlik Sense",
        title: 'Qlik Sense',
        description: "Guidé par un expert certifié, apprenez à maitriser l'outil Qlik Sense pour tous vos projets de Data Visualisation.",
        size: "xl",
        key: uuidv4(),
        courses: [
            {
                title: "Qliksense",
                image: QlikSense,
                alt: "Qlik Sense",
                size: "xl",
            },
        ]
    },
    {
        id: 7,
        slug: "sap",
        technos: [technos.BI],
        image: SAPBusinessObject,
        alt: "SAP",
        title: 'SAP',
        description: "Guidé par un expert certifié, apprenez à maitriser l'outil SAP Business Objects pour tous vos projets Data.",
        size: "xl",
        key: uuidv4(),
        courses: [
            {
                title: "SAP Business BI 4",
                image: SAPBusinessBI4,
                alt: "SAP Business BI 4",
                size: "xs",
            },
            {
                title: "SAP BO XI",
                image: SAPBusinessObject,
                alt: "SAP BO XI",
                size: "xl",
            },
        ]
    },
    {
        id: 8,
        slug: "ted-business-analyst-rpa",
        technos: [],
        image: TedLogo,
        alt: "TED consulting",
        title: 'Business Analyst RPA',
        description: "Guidé par un expert certifié, devenez Business Analyst en RPA",
        size: "xl",
        key: uuidv4(),
        type: "internal",
        courses: [
            {
                title: "Business Analyst RPA",
                image: TedLogo,
                alt: "Business Analyst RPA",
                size: "xl",
            },
        ]
    },
    {
        id: 9,
        slug: "ted-gestion-de-projet-automation",
        technos: [],
        image: TedLogo,
        alt: "Ted consulting",
        title: 'Gestion de Projet Automation',
        description: "Guidé par un expert certifié, apprenez à maitriser la gestion de projet dans le domaine de l'automatisation",
        size: "xl",
        key: uuidv4(),
        type: "internal",
        courses: [
            {
                title: "Gestion de Projet Automation",
                image: TedLogo,
                alt: "Gestion de Projet Automation",
                size: "xl",
            },
        ]
    },
]