// ----- Images ----- 
import Diagram from '../../../../images/solutions/tailorMade/diagram.png';
import Statistics from '../../../../images/solutions/tailorMade/statistics.png';
import Settings from '../../../../images/solutions/tailorMade/settings.png';
import Chat from '../../../../images/solutions/tailorMade/chat.png';

export const solutionRpa = [
    {
        id: 1,
        title: "Consulting",
        descriptions: [
            {
                id: 1,
                text: "Audit de procesuss métiers & études des ROI",
            },
            {
                id: 2,
                text: "Benchmark et recommandations sur le choix des outils RPA",
            },
            {
                id: 3,
                text: "Stratégie de déploiement d'un programme RPA",
            },
            {
                id: 4,
                text: "Process Discovery : atelier de design thinking avec les métiers",
            },
        ],
        img: Diagram,
        alt: "Deux personnes qui regardent des statistiques sur une tablette mobile",
    },
    {
        id: 2,
        title: "Intégration",
        descriptions: [
            {
                id: 5,
                text: "Benchmark et recommandations outils",
            },
            {
                id: 6,
                text: "Développement de Dashboards",
            },
            {
                id: 7,
                text: "Gestion des DataMarts & flux ETL",
            },
            {
                id: 8,
                text: "Data Mining & Data Cleaning",
            },
        ],
        img: Statistics,
        alt: "De la Dataviz sur une tablette mobile",
    },
    {
        id: 3,
        title: "Services managés",
        descriptions: [
            {
                id: 9,
                text: "Installation & conseil infra/architecture IT",
            },
            {
                id: 10,
                text: "Automation-as-a-Service",
            },
            {
                id: 11,
                text: "Gestion et vente de licenses",
            },
            {
                id: 12,
                text: "TMA (Maintenance & Support)",
            },
        ],

        img: Settings,
        alt: "Dataviz avec de multiple options au bout d'un doigt",
    },
    {
        id: 4,
        title: "Formation et conduite du changement",
        descriptions: [
            {
                id: 13,
                text: "Formation développeur RPA",
            },
            {
                id: 14,
                text: "Formation chef de projet / Business Analyste RPA",
            },
            {
                id: 15,
                text: "Mentoring & Coaching (Digital Factory, IT, C-levels)",
            },
            {
                id: 16,
                text: "Formation Technique & Administration des robots",
            },
        ],
        img: Chat,
        alt: "De la Dataviz sur une tablette mobile",
    },

]