// ----- Images ----- 
import Diagram from '../../../../images/solutions/tailorMade/diagram.png';
import Statistics from '../../../../images/solutions/tailorMade/statistics.png';
import Settings from '../../../../images/solutions/tailorMade/settings.png';
import Chat from '../../../../images/solutions/tailorMade/chat.png';

export const solutionBi = [
    {
        id: 1,
        title: "Consulting",
        descriptions: [
            {
                id: 1,
                text: "Audits : Business Intelligence, Modèle de données, gouvernance, DQM...",
            },
            {
                id: 2,
                text: "Benchmark et recommandations sur le choix des outils et architecture Data",
            },
            {
                id: 3,
                text: "Stratégie de déploiement d'un projet Data",
            },
            {
                id: 4,
                text: "Maquettage & atelier de design thinking avec les métiers",
            },
        ],
        img: Diagram,
        alt: "Deux personnes qui regardent des statistiques sur une tablette mobile",
    },
    {
        id: 2,
        title: "Intégration",
        descriptions: [
            {
                id: 5,
                text: "Réalisation de POC & Pilotes",
            },
            {
                id: 6,
                text: "Développement de tableaux de bords avec des solutions de DataViz",
            },
            {
                id: 7,
                text: " Développement du modèle de données, DatawareHouse, ETL, DataMarts...",
            },
            {
                id: 8,
                text: "Orchestration des flux de données et planification des jobs",
            },
        ],
        img: Statistics,
        alt: "De la Dataviz sur une tablette mobile",
    },
    {
        id: 3,
        title: "Exploitation et gouvernance",
        descriptions: [
            {
                id: 9,
                text: "Installation & conseil infra/architecture IT",
            },
            {
                id: 10,
                text: "Reporting-as-a-Service",
            },
            {
                id: 11,
                text: "Gestion et vente de licenses",
            },
            {
                id: 12,
                text: "TMA (Maintenance & Support)",
            },
        ],
        img: Settings,
        alt: "Dataviz avec de multiple options au bout d'un doigt",
    },
    {
        id: 4,
        title: "Formation et conduite du changement",
        descriptions: [
            {
                id: 13,
                text: "Formation Data Visualisation",
            },
            {
                id: 14,
                text: "Formation chef de projet DataViz",
            },
            {
                id: 15,
                text: "Mentoring & Coaching (Digital Factory, IT, C-levels)",
            },
            {
                id: 16,
                text: "Formation Technique & Administration des serveurs de données",
            },
        ],
        img: Chat,
        alt: "De la Dataviz sur une tablette mobile",
    },

]