import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'


// ----- Services -----
import { setImageBySector } from '../../services/helpers/setImageBySector';

export default function Project({ project }) {
    const [description, setDescription] = useState("")
    const [illustration, setIllustration] = useState("")
    const [altIllustration, setAltIllustration] = useState("")

    useEffect(() => {
        if (project?.description.length > 160) {
            setDescription(project?.description.slice(0, 100) + "... ")
        } else {
            setDescription(project?.description)
        }

        setImageBySector(project?.sector, setIllustration, setAltIllustration)

        // eslint-disable-next-line
    }, [])

    return (
        <Link className="project"
            key={project.key}
            id={project.key}
            to={"/nos-projets/" + project?.slug}
        >
            <img src={illustration} alt={altIllustration} />
            <h3> {project?.title}</h3>
            <p>{description}  <i>Voir plus</i></p>
        </Link>
    )
}
