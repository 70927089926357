import React from 'react'

// ----- Component -----
import Presentation from '../components/partner/Presentation'
import AllPartners from '../components/partner/AllPartners'

// ----- Packages -----
import { Helmet, HelmetProvider } from 'react-helmet-async';


export default function Partners() {


  return (
    <HelmetProvider>

    <Helmet prioritizeSeoTags>
      <title>Nos partenaires</title>
      <meta name="description" content="Nous sommes partenaires et revendeurs de solutions - UiPath, Automation Anywhere, Winshuttle, Power BI, Tableau..." />
      <meta property="og:title" content="Nos partenaires" />
      <meta property="og:description" content="Nous sommes partenaires et revendeurs de solutions - UiPath, Automation Anywhere, Winshuttle, Power BI, Tableau..." />
    </Helmet>

    <div className="page" id="partnersPage">

      <Presentation />
      <AllPartners />

    </div>
    </HelmetProvider>

  )
}
