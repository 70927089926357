import React from 'react'

// ----- Images -----
import PortfolioIllustration from '../../images/videos/portfolio.svg'

export default function Presentation() {
    return (
        <section className="presentation component">
           
                <div className="text-presentation">
                    <h1>Nos démos vidéos</h1>
                    <p className="subtitle">Nous mettons en lignes différents types de contenus vidéos regroupant nos domaines d'expertises selon différents outils : </p>
                </div>

                <img src={PortfolioIllustration} alt="Portfolio Ted, Demo Uipath & Tableau" />
           
        </section>
    )
}
