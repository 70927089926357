
// ----- Images ----
import PharmaSector from '../../images/project/Pharmaceutique.png';
import BankAndInsuranceSectors from '../../images/project/Banque_ Assurance.png';
import RetailSector from '../../images/project/Retail.png';
import IndustrySector from '../../images/project/Industrie.png';
import ServiceSector from '../../images/project/Service.png';
import RealEstateSector from '../../images/project/Immobilier.png';
import EnergySector from '../../images/project/Energie.png';

// ----- Services -----
import { sectors } from '../constants/sectors';


export const setIconBySector = (projectSector, setIcon, setName) => {
    switch (projectSector) {
        case sectors.PHARMA:
            setIcon(PharmaSector)
            setName("Pharma")
            break;
        case sectors.BANKANDINSURANCE:
            setIcon(BankAndInsuranceSectors)
            setName("Banque/Assurance")
            break;
        case sectors.RETAIL:
            setIcon(RetailSector)
            setName("Retail")
            break;
        case sectors.INDUSTRY:
            setIcon(IndustrySector)
            setName("Industrie")
            break;
        case sectors.SERVICE:
            setIcon(ServiceSector)
            setName("Service")
            break;
        case sectors.REALESTATE:
            setIcon(RealEstateSector)
            setName("Immobilier")
            break;
        case sectors.ENERGY:
            setIcon(EnergySector)
            setName("Énergie")
            break;
        default:
            break;
    }
}