import React, { useEffect, useRef, useState } from 'react'
// import { Link } from 'react-router-dom'

// ----- Images -----
// import Icon from '../../images/icons/arrow-down.svg'

// ----- Services -----
import { revealText } from '../../services/helpers/revealText'
import { animationDirection } from '../../services/constants/animationDirection'
import { setImageBySector } from '../../services/helpers/setImageBySector';
import { setIconBySector } from '../../services/helpers/setIconBySector';
import { setIconByJob } from '../../services/helpers/setIconByJob';

export default function Presentation({ project }) {

    const [illustration, setIllustration] = useState("")
    const [altIllustration, setAltIllustration] = useState("")

    const [sectorIcon, setSectorIcon] = useState("")
    const [sectorName, setSectorName] = useState("")

    const [jobImage, setJobImage] = useState("")
    const [jobName, setJobName] = useState("")


    const leftSide = useRef()
    const rightSide = useRef()
    const firstPage = useRef()

    useEffect(() => {
        setImageBySector(project?.sector, setIllustration, setAltIllustration)
        setIconBySector(project?.sector, setSectorIcon, setSectorName)
        setIconByJob(project?.job, setJobImage, setJobName)

        revealText(leftSide.current, animationDirection.TOLEFT, 0, 1)
        revealText(rightSide.current, animationDirection.TORIGHT, 0, 1)

        // eslint-disable-next-line
    }, [project])

    // const scrollToBottom = () => {
    //     window.scrollTo(0, 1000)
    // }
    return (


        <div className="page container-centered reverse" id="projectPresentationPage" ref={firstPage}>

            <div className="one-side" ref={rightSide}>
                <img className="project-image" src={illustration} alt={altIllustration} />
            </div>

            <div className="one-side " ref={leftSide}>
                <div className="reveal-text">
                    <h1 className="title-project">{project?.title}</h1>
                    <div className="sector-and-job">
                        <div className="sector">
                            <p className="title">Métier</p>
                            <p className="type">{jobName}</p>
                            <img src={jobImage} alt={"Logo du métier"} />
                        </div>
                        <div className="job">
                            <p className="title">Secteur</p>
                            <p className="type">{sectorName}</p>
                            <img src={sectorIcon} alt={"Logo du secteur d'activité"} />
                        </div>
                    </div>
                </div>

            </div>


            {/* <button id="scroll-arrow" onClick={() => scrollToBottom}>
                <img src={Icon} alt="flèche vers le bas" />
            </button> */}
        </div>
    )
}
