import React, { useState } from 'react'
import { Link } from 'react-router-dom'

// ----- Images -----
import Youtube from '../../images/icons/youtube.svg'
import Linkedin from '../../images/icons/linkedin.svg'
import Arrow from '../../images/icons/arrow-down.svg'


// ----- Packages -----
import gsap, { Power2 } from "gsap"
import Button from 'react-bootstrap/Button';

// ----- Components -----
import Solutions from './headerDropdown/Solutions'
import Projects from './headerDropdown/Projects'
import Resources from './headerDropdown/Resources'
import ContactUs from '../ContactUs'


export default function HeaderDesktop() {
  const [toggled, setToggled] = useState(false);

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);

  // ----- Toggled dropdown menu -----
  const toggledMenu = (e) => {
    const idToggled = e.currentTarget.id;
    const tl = gsap.timeline()

    setToggled(toggled ? false : true)

    if (!toggled) {
      tl.to('.dropdown-container', { display: 'none', opacity: 0, height: 0, duration: .2 });
      tl.to('#' + idToggled + '-dropdown', { display: 'flex', opacity: 1, height: "auto", duration: .5, ease: Power2 });
      tl.set('#' + idToggled + ' .arrow', { transform: 'rotate(180deg)' });
      tl.to('#' + idToggled, { textDecoration: 'underline 3px', duration: 0.2 });

    } else {
      tl.to('.dropdown-container', { display: 'none', opacity: 0, height: 0, duration: .5, ease: Power2 });
      tl.set('#' + idToggled + ' .arrow', { transform: 'rotate(0deg)' });
      tl.to('.item-toggled', { textDecoration: 'none', duration: 0.2 });

    }
  };


  return (
    <header id="header-desktop">

      {/* ----- Navbar Visible  -----*/}
      <nav>
        {/* ----- Left  -----*/}
        <div id="nav-left">
          <a href={process.env.REACT_APP_YOUTUBE_CHANNEL} target="_blank" rel="noopener noreferrer"><img src={Youtube} alt="Youtube rounded" /></a>
          <a href={process.env.REACT_APP_LINKEDIN_CHANNEL} target="_blank" rel="noopener noreferrer"><img src={Linkedin} alt="Linkedin rounded" /></a>
        </div>


        {/* ----- Centered -----*/}
        <div id="nav-centered">
          <div className="item-container">
            <Link to={"/qui-sommes-nous"}>Qui sommes nous?</Link>
          </div>

          <div className="item-container">
            <div className="item-toggled" id="solutions" onClick={toggledMenu}>
              <p>Solutions</p>
              <img src={Arrow} alt="flèche blanche" className='arrow' />
            </div>
            {/*  ---- Dropdown menu ----- */}
            <div className="dropdown-container" id="solutions-dropdown">
              <Solutions />
            </div>
          </div>


          <Link to={"/"}><div id="logo"></div></Link>


          <div className="item-container">
            <div className="item-toggled" id="projects" onClick={toggledMenu}>
              <p>Nos projets</p>
              <img src={Arrow} alt="flèche blanche" className='arrow' />
            </div>
            {/*  ---- Dropdown menu ----- */}
            <div className="dropdown-container" id="projects-dropdown">
              <Projects />
            </div>
          </div>

          <div className="item-container">
            <div className="item-toggled" id="resources" onClick={toggledMenu}>
              <p>Ressources</p>
              <img src={Arrow} alt="flèche blanche" className='arrow' />
            </div>

            <div className="dropdown-container" id="resources-dropdown">
              <Resources />
            </div>
          </div>
        </div>


        {/*  ---- Right ----- */}
        <div id="nav-right">
          <Button className="btn-default btn-white" variant="primary" onClick={handleShow}>Nous contacter</Button>
        </div>
      </nav>
      {/*  ---- Popup Contact Us ----- */}
      <ContactUs show={show} setShow={setShow} />


    </header >
  )
}
