import React from 'react'

// ----- Component -----
import CourseByTool from './CourseByTool'

export default function CoursesByTools({ course }) {

    return (
        <>
            <div className="component">

                <h2>Formations par outils</h2>

                <div className="partners-container container-centered">
                    {
                        course.courses && course.courses.map((detailsCourse, index) =>
                            <CourseByTool detailsCourse={detailsCourse} />
                        )
                    }
                </div>
            </div>
        </>
    )
}
