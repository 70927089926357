import React from 'react'

export default function Partner({ partner }) {

    return (
        <div className="partner" key={partner?.key}>
            <div className="image-container">
                <img src={partner?.image} alt={partner?.alt} className={`${partner.size === "xl" ? "xl-logo" : partner.size === "xs" ? "xs-logo" : "md-logo"}`} />
            </div>
            <h3>{partner?.title}</h3>
            <p>{partner?.description}</p>
        </div>
    )
}
