import React from 'react'

// ----- Services -----
import { scrollReveal, scrollOpacityText } from '../services/helpers/revealText'
import { revealImage } from '../services/helpers/revealImage'

// ----- Images -----
import WavesGreen from '../images/waves-green.svg'

export default function Video() {

    // ----- Opinions animation -----
    scrollReveal('#videoBSmar iframe', true)

    // ----- Title animation -----
    scrollOpacityText('#videoBSmart h2')

    // ----- Reveal background animation -----
    revealImage("#videoBSmart .img-container", "#videoBSmart .img-overlay", "#videoBSmart .img-overlay img")


    return (
        <div className="component" id="videoBSmart">

            {/* ----- Waves Background ----- */}
            <div className="img-container">
                <div className="img-overlay"></div>
                <img src={WavesGreen} alt="vagues vertes" />
            </div>

            <iframe
                src="https://www.youtube.com/embed/BBbxDrfpUag"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            >
            </iframe>

            <h2>Interview B SMART - Ted</h2>
            <p>
                Mathias Chaumon & Emmanuel Kuranty <br />
                parlent RPA et transformation digitale des métiers de la Finance
            </p>
        </div>
    )
}
