// ----- Images ----
import PharmaIllustration from '../../images/project/presentation/pharmacie.png';
import BankIllustration from '../../images/project/presentation/banque_assurance.png';
import RetailIllustration from '../../images/project/presentation/retail.png';
import IndustryIllustration from '../../images/project/presentation/industrie.png';
import ServiceIllustration from '../../images/project/presentation/service.png';
import RealEstateIllustration from '../../images/project/presentation/immobilier.png';
import EnergyIllustration from '../../images/project/presentation/energie.png';

// ----- Services -----
import { sectors } from '../constants/sectors';

export const setImageBySector = (projectSector, setIllustration, setAltIllustration) => {
    switch (projectSector) {
        case sectors.PHARMA:
            setIllustration(PharmaIllustration)
            setAltIllustration("Une ampoule éclairé avec une grande ville en arrière plan")
            break;
        case sectors.BANKANDINSURANCE:
            setIllustration(BankIllustration)
            setAltIllustration("Une ampoule éclairé avec une grande ville en arrière plan")
            break;
        case sectors.RETAIL:
            setIllustration(RetailIllustration)
            setAltIllustration("Une ampoule éclairé avec une grande ville en arrière plan")
            break;
        case sectors.INDUSTRY:
            setIllustration(IndustryIllustration)
            setAltIllustration("Une ampoule éclairé avec une grande ville en arrière plan")
            break;
        case sectors.SERVICE:
            setIllustration(ServiceIllustration)
            setAltIllustration("Une ampoule éclairé avec une grande ville en arrière plan")
            break;
        case sectors.REALESTATE:
            setIllustration(RealEstateIllustration)
            setAltIllustration("Une ampoule éclairé avec une grande ville en arrière plan")
            break;
        case sectors.ENERGY:
            setIllustration(EnergyIllustration)
            setAltIllustration("Une ampoule éclairé avec une grande ville en arrière plan")
            break;
        default:
            break;
    }
}