// ----- Constants ----- 
import { technos } from '../technos';

// ----- Images ----- 
import ArraySvg from '../../../images/solutions/prestations/array.png'
import AuditSvg from '../../../images/solutions/prestations/audit.png'
import DashboardSvg from '../../../images/solutions/prestations/dashboard.png'
import HandSettingsSvg from '../../../images/solutions/prestations/hand_settings.png'
import SearchSvg from '../../../images/solutions/prestations/search.png'
import Roadmap from '../../../images/solutions/prestations/roadmap.png'

export const prestations = [
    {
        type: technos.RPA,
        prestations: [
            {
                id: 1,
                title: "Discovery Meeting",
                description: "Vous souhaitez identifier des processus à automatiser, à transformer, à digitaliser?",
                link: "/prestations/" + technos.RPA + "/" + 1,
                icon: SearchSvg,
                alt: "Apoule dans une loupe pour l'analyse",
            },
            {
                id: 2,
                title: "Audit",
                description: "Vous souhaitez analyser votre processus et en estimer les gains (ROI) potentiels avant d'investir?",
                link: "/prestations/" + technos.RPA + "/" + 2,
                icon: AuditSvg,
                alt: "Feuille d'audit",
            },
            {
                id: 3,
                title: "Roadmap",
                description: "Vous souhaitez avoir une vision claire sur la stratégie de déploiement de vos outils ou d’un plan de transformation digitale",
                link: "/prestations/" + technos.RPA + "/" + 3,
                icon: Roadmap,
                alt: "Roadmap",
            },
            {
                id: 4,
                title: "Projet",
                description: "Vous souhaitez lancer un projet RPA pour robotiser des tâches",
                link: "/prestations/" + technos.RPA + "/" + 4,
                icon: ArraySvg,
                alt: "Tableau de données contenant des statistiques",
            },
            {
                id: 5,
                title: "TMA",
                description: "Vous souhaitez déléguer la maintenance corrective et évolutive de vos robots & assistants virtuels",
                link: "/prestations/" + technos.RPA + "/" + 5,
                icon: HandSettingsSvg,
                alt: "Main contenant un engrenage",
            },
            {
                id: 6,
                title: "Formation",
                description: "Vous souhaitez acquérir des compétences sur les solutions de RPA",
                link: "/prestations/" + technos.RPA + "/" + 6,
                icon: DashboardSvg,
                alt: "Personnage présentant un dashboard avec des données à une équipe d'experts",
            },

        ]
    },
    {
        type: technos.BPM,
        prestations: [
            {
                id: 1,
                title: "Roadmap",
                description: "Vous souhaitez mettre en place un plan stratégique d'identification et d'audit de vos processus",
                link: "/prestations/" + technos.BPM + "/" + 1,
                icon: Roadmap,
                alt: "Roadmap",
            },
            {
                id: 2,
                title: "Projet",
                description: "Vous souhaitez lancer un projet BPM, Task mining ou Process Mining",
                link: "/prestations/" + technos.BPM + "/" + 2,
                icon: ArraySvg,
                alt: "Tableau de données contenant des statistiques",
            },
            {
                id: 3,
                title: "Formation",
                description: "Vous souhaitez vous former sur le concept de BPM, des outils de Task mining ou process mining",
                link: "/prestations/" + technos.BPM + "/" + 3,
                icon: DashboardSvg,
                alt: "Personnage présentant un dashboard avec des données à une équipe d'experts",
            },

        ]
    },
    {
        type: technos.BI,
        prestations: [
            {
                id: 1,
                title: "Discovery Meeting",
                description: "Vous souhaitez identifiez les tableaux de bords et indicateurs à construire",
                link: "/prestations/" + technos.BI + "/" + 1,
                icon: SearchSvg,
                alt: "Apoule dans une loupe pour l'analyse",
            },
            {
                id: 2,
                title: "Audit",
                description: "Vous souhaitez auditer la qualité de vos données, gouvernance ou modèle de données",
                link: "/prestations/" + technos.BI + "/" + 2,
                icon: AuditSvg,
                alt: "Feuille d'audit",
            },
            {
                id: 3,
                title: "Roadmap",
                description: "Vous souhaitez avoir une vision claire sur la stratégie de déploiement de vos outils ou d’un plan de transformation digitale ",
                link: "/prestations/" + technos.BI + "/" + 3,
                icon: Roadmap,
                alt: "Roadmap",
            },
            {
                id: 4,
                title: "Projet",
                description: "Vous souhaitez lancer un projet BI ou Data visualisation",
                link: "/prestations/" + technos.BI + "/" + 4,
                icon: ArraySvg,
                alt: "Tableau de données contenant des statistiques",
            },
            {
                id: 5,
                title: "TMA",
                description: "Vous souhaitez déléguer la maintenance corrective et évolutive de vos tableaux de bord",
                link: "/prestations/" + technos.BI + "/" + 5,
                icon: HandSettingsSvg,
                alt: "Main contenant un engrenage",
            },
            {
                id: 6,
                title: "Formation",
                description: "Vous souhaitez acquérir des compétences sur les solutions de Data Management et DataViz",
                link: "/prestations/" + technos.BI + "/" + 6,
                icon: DashboardSvg,
                alt: "Personnage présentant un dashboard avec des données à une équipe d'experts",
            },

        ]
    },
    {
        type: technos.IA,
        prestations: [
            {
                id: 1,
                title: "Discovery meeting",
                description: "Vous souhaitez lancer une phase exploratoire d'intelligence articifielle et vous avez besoin d'être guidé dans la mise en place",
                link: "/prestations/" + technos.BPM + "/" + 1,
                icon: SearchSvg,
                alt: "Apoule dans une loupe pour l'analyse",
            },
            {
                id: 2,
                title: "Pilote/POC",
                description: "Vous souhaitez passer de l'idéation à la réalisation en testant l'IA sur un périmètre précis, un échantillon de données",
                link: "/prestations/" + technos.BPM + "/" + 2,
                icon: AuditSvg,
                alt: "Feuille d'audit",
            },
            {
                id: 3,
                title: "Projet",
                description: "Vous souhaitez lancer un projet avec une équipe dédiée aux sujets IA avec des Data Scientists",
                link: "/prestations/" + technos.BPM + "/" + 3,
                icon: DashboardSvg,
                alt: "Personnage présentant un dashboard avec des données à une équipe d'experts",
            },

        ]
    },
    {
        type: technos.DEVOPS,
        prestations: [
            {
                id: 1,
                title: "Discovery meeting",
                description: "Vous souhaitez identifier des projets autoutr du Test, Planification, Supervision, et vous avez besoin d'être guidé dans la mise en place",
                link: "/prestations/" + technos.BPM + "/" + 1,
                icon: SearchSvg,
                alt: "Apoule dans une loupe pour l'analyse",
            },
            {
                id: 2,
                title: "Pilote/POC",
                description: "Vous souhaitez passer de l'idéation à la réalisation en testant un projet pilote sur un périmètre précis, un échantillon de données",
                link: "/prestations/" + technos.BPM + "/" + 2,
                icon: AuditSvg,
                alt: "Feuille d'audit",
            },
            {
                id: 3,
                title: "Projet",
                description: "Vous cherchez de profils Devops pour vous accompagner au quoitidien",
                link: "/prestations/" + technos.BPM + "/" + 3,
                icon: DashboardSvg,
                alt: "Personnage présentant un dashboard avec des données à une équipe d'experts",
            },

        ]
    },
]