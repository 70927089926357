import React from 'react'
import { Link } from 'react-router-dom'

// ----- Images -----
import RosaceGreen from '../images/logos/rosace-green.png'

export default function NotFound() {
  return (
    <div id="notFound" className="page">

      <div className="text-container">
        <h1>Oops.. </h1>
        <h2>Cette page est introuvable...</h2>
      </div>
      <img src={RosaceGreen} alt="rosace verte TED consulting" />
      <Link to={"/"} className="btn-default btn-orange">Retour à l'accueil</Link>

    </div>
  )
}
