import axios from 'axios';

// const API_KEY = "my_key";
// const BASE_URI = process.env.REACT_APP_URI_ENDPOINT
const BLOG_URI = process.env.REACT_APP_URI_BLOG

const headers = {
    'Content-Type': 'application/json',
}

// ----- Get all posts ----- 
export function getPostsBlog(setResponse) {
    axios({
        url: BLOG_URI + 'posts',
        headers: headers,
    })
        .then((response) => {
            if (response.data) {
                setResponse(response.data)
                // setTotalPages(response.headers['x-wp-totalpages'])
            }
        }, (err) => {
            setResponse(err)
        });
}

// ----- Get post by id ----- 
export function getPostBlogBySlug(setResponse, setLoading, slug) {
    axios({
        url: BLOG_URI + 'posts/?slug=' + slug,
        headers: headers,
    })
        .then((response) => {
            if (response.data) {
                setResponse(response.data[0])
                setLoading(false)
            }
        }, (err) => {
            setResponse(err)
        });
}

// ----- Get all medias ----- 
export function getMedias(setResponse) {
    axios({
        url: BLOG_URI + 'media',
        headers: headers,
    })
        .then((response) => {
            if (response.data) {
                setResponse(response.data)
            }
        }, (err) => {
            setResponse(err)
        });
}

// ----- Get all comments ----- 
export function getComments(setResponse) {
    axios({
        url: BLOG_URI + 'comments',
        headers: headers,
    })
        .then((response) => {
            if (response.data) {
                setResponse(response.data)
            }
        }, (err) => {
            setResponse(err)
        });
}
// ----- Get comments by id ----- 
export function getCommentsByPost(setResponse, id) {
    axios({
        url: BLOG_URI + 'comments?post=' + id,
        headers: headers,
    })
        .then((response) => {
            if (response.data) {
                setResponse(response.data)
            }
        }, (err) => {
            setResponse(err)
        });
}

// ----- Get tags by id ----- 
export function getTagsByPost(setResponse, id) {
    axios({
        url: BLOG_URI + 'tags?post=' + id,
        headers: headers,
    })
        .then((response) => {
            if (response.data) {
                setResponse(response.data)
            }
        }, (err) => {
            setResponse(err)
        });
}

// ----- Get latest posts ----- 
export function getRecentPosts(setResponse, id) {
    axios({
        url: BLOG_URI + 'posts?page=1&per_page=3&exclude='+ id,
        headers: headers,
    })
        .then((response) => {
            if (response.data) {
                setResponse(response.data)
            }
        }, (err) => {
            setResponse(err)
        });
}

