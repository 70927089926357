import React, { useEffect, useState, useMemo } from 'react'
import { Link } from 'react-router-dom'

// ----- Constants -----
import { partners } from '../services/constants/components/partners';
import { technos } from '../services/constants/technos';

// ----- Packages -----
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';


export default function Partners({ title, solution, location }) {

  // const [isLoaded, setIsLoaded] = useState(false)
  const [filteredArray, setFilteredArray] = useState([])

  const loadCarousel = useMemo(() => {
    setFilteredArray([])

    if (solution && solution !== "") {
      partners.forEach(partner => {
        let findByTechno = false

        if (technos) {
          if (partner.technos.includes(solution)) {
            findByTechno = true
          }
        } else {
          findByTechno = true
        }

        if (findByTechno) {
          setFilteredArray(arr => [...arr, <img src={partner.image} alt={partner.alt} className={`${partner.size === "xl" ? "xl-logo" : partner.size === "xs" ? "xs-logo" : "md-logo"}`} key={partner.key} />]);
        }

        findByTechno = false
      })

    } else {
      partners.forEach(partner => {
        setFilteredArray(arr => [...arr, <img src={partner.image} alt={partner.alt} className={`${partner.size === "xl" ? "xl-logo" : partner.size === "xs" ? "xs-logo" : "md-logo"}`} key={partner.key} />]);
      })
    }

    // eslint-disable-next-line
  }, [technos, location]);

  useEffect(() => {
    return loadCarousel
  }, [loadCarousel])



  return (
    <div className="component" id="partners">

      <h2>Partenaires & certifications {title && title}</h2>

      <p className="subtitle">
        Afin d'accompagner au mieux nos clients, nous avons un lien fort avec nos partenaires éditeurs.
        Nous suivons les évolutions des technologies maitrisées et intégrées par nos équipes conseil et nous nous assurons par ces accréditations de pouvoir délivrer des prestations de services de qualité.
      </p>

      <div className="container-carousel">
        {/* {isLoaded && */}
        <AliceCarousel
          mouseTracking
          controlsStrategy={'responsive'}
          items={filteredArray}
          infinite={true}
          responsive={{
            0: {
              items: 3,
            },
            992: {
              items: 3,
            },
            1200: {
              items: 4,
            },
            1350: {
              items: 5,
            }
          }} />
        {/* } */}
      </div>

      <div>
        <Link to={"/partenaires"} className="mt-5 btn-default btn-blue">Voir tous nos partenaires</Link>
      </div>

    </div>
  )
}
