import React, { useState, useEffect } from 'react'

// ----- Images -----
import PodcastCharbon from '../../images/mathias-podcast-charbon.jpeg'
import WebinarAlmond from '../../images/videos/webinar_almond.png'
// import RosaceBlue from '../../images/logos/rosace-blue.png'
// import RosaceOrange from '../../images/logos/rosace-orange.png'

// ----- Constants -----
import { videos } from '../../services/constants/components/videos';
import { videoTypes } from '../../services/constants/videoTypes';

// ----- Packages -----
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';


// ----- Component -----
import VideoComponent from './OneVideo'

export default function AllVideos() {
    const [isLoaded, setIsLoaded] = useState(false)

    const [uiPathVideo, setUiPathVideo] = useState([])
    const [automationAnywhereVideo, setAutomationAnywhereVideo] = useState([])
    const [powerBiVideo, setPowerBiVideo] = useState([])
    const [tableauSoftwareVideo, setTableauSoftwareVideo] = useState([])

    useEffect(() => {
        setIsLoaded(true)
    }, [])


    useEffect(() => {
        setUiPathVideo([])
        setAutomationAnywhereVideo([])
        setPowerBiVideo([])
        setTableauSoftwareVideo([])

        videos.forEach(video => {

            if (video.type === videoTypes.UIPATH) {
                setUiPathVideo(arr => [...arr, <VideoComponent video={video} />])
            } else if (video.type === videoTypes.AUTOMATIONANYWHERE) {
                setAutomationAnywhereVideo(arr => [...arr, <VideoComponent video={video} />])
            } else if (video.type === videoTypes.POWERBI) {
                setPowerBiVideo(arr => [...arr, <VideoComponent video={video} />])
            } else if (video.type === videoTypes.TABLEAUSOFTWARE) {
                setTableauSoftwareVideo(arr => [...arr, <VideoComponent video={video} />])
            }
        });
        // eslint-disable-next-line
    }, [videos])


    return (
        <>
            <section>
                <div className="title-container">
                    <h2>Démo UiPath</h2>
                </div>

                <div className="partners-container container-centered">
                    {isLoaded &&
                        <AliceCarousel
                            mouseTracking
                            controlsStrategy={'responsive'}
                            items={uiPathVideo}
                            infinite={true}
                            responsive={{
                                0: {
                                    items: 1,
                                },
                                768: {
                                    items: 2,
                                },
                                1350: {
                                    items: 3,
                                }
                            }} />
                    }
                </div>
            </section>

            <section className="component">
                <div className="title-container">
                    <h2>Démo Automation Anywhere</h2>
                </div>

                <div className="partners-container container-centered">
                    {isLoaded &&
                        <AliceCarousel
                            mouseTracking
                            controlsStrategy={'responsive'}
                            items={automationAnywhereVideo}
                            infinite={true}
                            disableButtonsControls={true}
                            responsive={{
                                0: {
                                    items: 1,
                                },
                                768: {
                                    items: 2,
                                },
                                1350: {
                                    items: 3,
                                }
                            }} />
                    }

                </div>
            </section>


            <section className="component">
                <div className="title-container">
                    <h2>Démo Power BI</h2>
                </div>

                <div className="partners-container container-centered">
                    {isLoaded &&
                        <AliceCarousel
                            mouseTracking
                            controlsStrategy={'responsive'}
                            items={powerBiVideo}
                            infinite={true}
                            responsive={{
                                0: {
                                    items: 1,
                                },
                                768: {
                                    items: 2,
                                },

                                1350: {
                                    items: 3,
                                }
                            }} />
                    }
                </div>

                {/* AJOUTER EMBED DU COMPTE POWER BI DIRECTEMENT */}
            </section>

            <section className="component">
                <div className="title-container">
                    <h2>Démo Tableau Software</h2>
                </div>

                <div className="partners-container container-centered">

                    {isLoaded &&
                        <AliceCarousel
                            mouseTracking
                            controlsStrategy={'responsive'}
                            items={tableauSoftwareVideo}
                            infinite={true}
                            responsive={{
                                0: {
                                    items: 1,
                                },
                                768: {
                                    items: 2,
                                },

                                1350: {
                                    items: 3,
                                }
                            }} />
                    }

                    <a href={"https://public.tableau.com/app/profile/ted7739#!/"} className="btn-default btn-orange mx-auto mt-3">Consulter nos tableaux</a>
                </div>
            </section>
            <section className="component">
                <div className="title-container">
                    <h2>Le replay de nos webinaires :</h2>
                </div>

                <div className="partners-container container-centered">
                    {isLoaded &&
                        <div className='alice-carousel'>
                            <div className="video-component">
                                <a href={"https://youtu.be/iPPNhhfgGXc"} target='_blank' rel="noreferrer" >
                                    <img src={WebinarAlmond} alt={"Vidéo youtube TED consulting - Webinar RPA : Comment automatiser en garantissant Sécurité et Conformité SI ?"} />
                                    {/* <iframe src={video.link} title={video.title} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> */}
                                    <p>Webinar RPA : Comment automatiser en garantissant Sécurité et Conformité SI ?</p>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" fill="currentColor" class="bi bi-play-btn-fill" viewBox="0 0 16 16">
                                        <path d="M0 12V4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm6.79-6.907A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z" />
                                    </svg>
                                </a>
                            </div>
                        </div>
                    }
                </div>
            </section>

            <section className="component">
                <div className="title-container">
                    <h2>Allez plus loin, découvrez les podcasts dans lesquelles Ted apparaît :</h2>
                </div>

                <div className="partners-container container-centered">
                    {isLoaded &&

                        <a className="podcast-container video-component mt-3"
                            href={"https://podcast.ausha.co/charbon/41-mathias-chaumon-l-importance-de-l-empathie-dans-l-entrepreneuriat"}
                            target="_blank" rel="noopener noreferrer"
                        >
                            <img src={PodcastCharbon} alt={"podcast Charbon de Mathias Chaumon, CEO de Ted consulting"} />
                            {/* <audio controls>
                                <source src="https://audiofiles.ausha.co/nl-ams/09/xTltdTlPbvqUrDxTOmZKuF3arG508PcZkLFMAnOq.mp3?token=1670526876-Iw%2BcqH7zCgCHb%2Fy5OsMkGRvfAgNB6oviXm%2BioXVkG90%3D" />
                            </audio> */}
                            <p>#41 - Mathias Chaumon : L'importance de l'empathie dans l'entrepreneuriat</p>
                        </a>
                    }
                </div>
            </section>

        </>
    )
}
