import React, { useEffect, useState, useMemo } from 'react'
import { Link } from 'react-router-dom'

// ----- Packages -----
import gsap from 'gsap'
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

// ----- Services -----
import { scrollReveal } from '../../services/helpers/revealText'
import { getMediaType } from '../../services/helpers/getMediaType'

// ----- Constants -----
import { projects } from '../../services/constants/components/projects';

// ----- Component -----
import Project from './Project';

export default function OurProjects({ techno, title, ourProjectsRef, favorite }) {

    const [filteredArray, setFilteredArray] = useState([])
    const [isMobile, setIsMobile] = useState(false)


    const [count, setCount] = useState(0)

    const loadCarousel = useMemo(() => {
        setFilteredArray([])


        projects.forEach(project => {
            if (techno) {
                if (project.technos.includes(techno)) {
                    setFilteredArray(arr => [...arr, <Project project={project} />]);
                }
            } else if (favorite) {
                if (project.favorite) {
                    setFilteredArray(arr => [...arr, <Project project={project} />]);
                }
            } else {
                setFilteredArray(arr => [...arr, <Project project={project} />]);
            }
        })
    }, [techno, favorite]);


    useEffect(() => {
        return loadCarousel
    }, [loadCarousel])


    useEffect(() => {
        if (count === 0) {
            setTimeout(() => {
                gsap.to('.alice-carousel', { css: { width: "101%" }, duration: 0 });
                setCount(1)
            }, 1000);
        }
    }, [count])


    scrollReveal('#ourProjects h2', false)


    // ----- 
    // Listener media 
    // -----
    useEffect(() => {
        // ------ 
        // Display content based on media type 
        // -----
        getMediaType(setIsMobile)
        window.addEventListener("resize", (e) => {
            getMediaType(setIsMobile)

        });
    }, [])

    useEffect(() => {
        if (isMobile) {
            gsap.set('.alice-carousel__dots', { css: { display: "none" } });
        }
    }, [isMobile])



    return (
        <div id="projets" className="component" ref={ourProjectsRef}>

            <h2>Nos projets {title && title}</h2>

            <p className="subtitle">
                Nous nous attachons à documenter nos projets et à les analyser une fois la mise en production effecutée chez nos clients.
                Découvrez les projets et cas d'usages qui vous permetront de mieux appréhender nos compétences et notre savoir-faire dans ce domaine d'innovation qu'est l'HyperAutomation
            </p>

            <div className="container-carousel">
                <AliceCarousel
                    mouseTracking
                    controlsStrategy={'responsive'}
                    items={filteredArray}
                    infinite={true}
                    responsive={{
                        0: {
                            items: 1,
                        },
                        768: {
                            items: 2,
                        },
                        1200: {
                            items: 3,
                        }
                    }} />
            </div>

            <div>
                <Link to={"/nos-projets"} className="mt-5 btn-default btn-orange">Voir tous nos projets</Link>
            </div>

        </div>
    )
}
