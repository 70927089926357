import React, { useEffect, useState } from 'react'

// ----- Components -----
import Solution from './Solution'

// ----- Services -----
import { scrollReveal, scrollOpacityText } from '../../../services/helpers/revealText'

// ----- Constants -----
import { solutions } from '../../../services/constants/components/solutions'


export default function OurSolutions() {

    // ----- Solution animation -----
    scrollReveal('#ourSolutions section', true)

    // ----- Title animation -----
    scrollOpacityText('h3')

    const [windowSize, setWindowSize] = useState(window.innerWidth);

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize(window.innerWidth);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });



    return (
        <div className="component" id="ourSolutions">

            <h2>Nos solutions</h2>

            {windowSize < 1200 ?
                solutions && solutions.map((solution, index) =>
                    <Solution
                        title={solution.title}
                        description={solution.description}
                        link={solution.link}
                        reverse={solution.reverse}
                        img={solution.img}
                        alt={solution.alt}
                        id={solution.id}
                        index={index}
                    />
                )

                :

                <div className="rosace-component component">
                    <div className='row justify-content-center'>

                        <div className="rounded-part">
                            <h3>BPM, Process <br /> & Task mining</h3>
                            <div className="hover-circle">
                                <p>BPM, Process <br /> & Task mining</p>
                                <span>
                                    Le Business Process Management est une manière de travailler qui permet d'analyser des processus métiers, de détecter des points de friction et d'estimer des potentiels de transformation.
                                    Le Task Mining et le Process Mining nous permettent d'aller à des degrés plus ou moins micro de cet audit des processus.
                                </span>
                                <a className="btn-default btn-orange" href={"/nos-solutions/bpm"}>En savoir plus</a>
                            </div>
                        </div>


                        <div className="rounded-part">
                            <h3>RPA</h3>
                            <div className="hover-circle">
                                <p>RPA</p>
                                <span>
                                    La RPA (Robotic Process automation) permet de robotiser des tâches chronophages et sans valeur ajoutée.
                                    Découvrez nos réalisations avec UiPath, Power Automate, Winshuttle et Automation Anywhere.
                                </span>
                                <a className="btn-default btn-orange" href={"/nos-solutions/rpa"}>En savoir plus</a>
                            </div>
                        </div>

                        <div className="rounded-part">
                            <h3>Bi & <br />Data</h3>
                            <div className="hover-circle">
                                <p>Bi & Data</p>
                                <span>
                                    Un processus métier est régi par des règles de gestion, opéré par des humains qui traitent de la données dans des logiciels.
                                    Nous accompagnons nos clients dans la strucutation de leur modèle BI et nous développons et automatisons les indicateurs qui leur permettent de piloter leurs activités.
                                </span>
                                <a className="btn-default btn-orange" href={"/nos-solutions/bi"}>En savoir plus</a>
                            </div>
                        </div>

                        <div className="rounded-part">
                            <h3>IA</h3>
                            <div className="hover-circle">
                                <p>IA</p>
                                <span>
                                    L'Intelligence Artificielle permet de rendre plus intelligents nos robots.
                                    Nous avons la capacité de traiter des tâches et des processus plus complexes comme la lecture de mails,
                                    la récupération de données, la génaration de textes, l'analyse de données....
                                </span>
                                <a className="btn-default btn-orange" href={"/nos-solutions/ia"}>En savoir plus</a>
                            </div>
                        </div>


                        <div className="rounded-part">
                            <h3>DevOps</h3>
                            <div className="hover-circle">
                                <p>DevOps</p>
                                <span>
                                    Notre équipe Devops permet de piloter, orchestrer et gérer les applications et nos API que nous développons en sur-mesure pour nos clients.
                                </span>
                                <a className="btn-default btn-orange" href={"/nos-solutions/devops"}>En savoir plus</a>
                            </div>
                        </div>

                    </div>
                </div>
            }

        </div>
    )
}

