import React, { useEffect, useRef } from 'react'

// ----- Components -----
import Presentation from '../components/solutions/Presentation'
import Prestations from '../components/solutions/Prestations'
import OurServices from '../components/solutions/services/OurServices'
import InnovationLab from '../components/solutions/InnovationLab'
import Partners from '../components/PartnersCarousel'
import OurProjects from '../components/projectsCarousel/OurProjects'


// ----- Constants -----
import { technos } from '../services/constants/technos';
import { solutions } from '../services/constants/components/solutions';


// ----- Packages -----
import { Helmet, HelmetProvider } from 'react-helmet-async';

export default function SolutionBi({ location }) {
  const ourServicesRef = useRef()
  const ourPrestationsRef = useRef()
  const ourProjectsRef = useRef()


  useEffect(() => {
    switch (location.hash) {
      case "#services":
        ourServicesRef.current.scrollIntoView()
        break;
      case "#prestations":
        ourPrestationsRef.current.scrollIntoView()
        break;
      case "#projets":
        ourProjectsRef.current.scrollIntoView()
        break;
      default:
        break;
    }
  }, [location])

  return (
    <HelmetProvider>


      <div className="ourSolution-page">

        {(() => {
          switch (location.pathname) {
            case "/nos-solutions/" + technos.RPA:
              return <>

                <Helmet prioritizeSeoTags>
                  <title>Solutions RPA - Automatisation des processus métiers</title>
                  <meta name="description" content="Robotisez et optimisez vos processus métiers. Découvrez nos projets Robotic Process Automation (RPA). Nous sommes partenaires UiPath, Automation Anywhere et Microsoft Power Automate." />
                  <meta property="og:title" content="Solutions RPA - Automatisation des processus métiers" />
                  <meta property="og:description" content="Robotisez et optimisez vos processus métiers. Découvrez nos projets Robotic Process Automation (RPA). Nous sommes partenaires UiPath, Automation Anywhere et Microsoft Power Automate." />
                </Helmet>

                {/* ----- First Page - Presentation ----- */}
                <Presentation
                  image={solutions[0].img}
                  alt={solutions[0].alt}
                  title={solutions[0].longTitle}
                  description={solutions[0].longDescription}
                  description2={solutions[0].longDescription2}
                />

                {/* ----- Our Prestations -----*/}
                <Prestations ourPrestationsRef={ourPrestationsRef} techno={technos.RPA} />

                {/* ----- Our Services -----*/}
                <OurServices ourServicesRef={ourServicesRef} techno={technos.RPA} title={"RPA"} />

                {/* ----- Projects -----*/}
                <OurProjects techno={technos.RPA} title={"RPA"} ourProjectsRef={ourProjectsRef} favorite={false} />

                {/* ----- Lab Innovation TED -----*/}
                <InnovationLab />

                {/* ----- Partners -----*/}
                <Partners location={location} title={"RPA"} solution={technos.RPA} />
              </>
            case "/nos-solutions/" + technos.IA:
              return <>

                <Helmet prioritizeSeoTags>
                  <title>Solutions IA - OCR & Algorithmes Intéligents</title>
                  <meta name="description" content="Explorez le potentiel de la Data Science et l'IA. Développement et intégration d'algorithmes, de modèles de Deep & Machine Learning." />
                  <meta property="og:title" content="Solutions IA" />
                  <meta property="og:description" content="Explorez le potentiel de la Data Science et l'IA. Développement et intégration d'algorithmes, de modèles de Deep & Machine Learning." />
                </Helmet>

                {/* ----- First Page - Presentation ----- */}
                <Presentation
                  image={solutions[1].img}
                  alt={solutions[1].alt}
                  title={solutions[1].longTitle}
                  description={solutions[1].longDescription}
                  description2={solutions[1].longDescription2}
                />


                {/* ----- Our Prestations -----*/}
                <Prestations ourPrestationsRef={ourPrestationsRef} techno={technos.IA} />

                {/* ----- Our Services -----*/}
                <OurServices ourServicesRef={ourServicesRef} techno={technos.IA} />

                {/* ----- Projects -----*/}
                <OurProjects techno={technos.IA} title={"IA"} ourProjectsRef={ourProjectsRef} favorite={false} />

                {/* ----- Partners -----*/}
                <Partners location={location} title={"IA"} solution={technos.IA} />
              </>
            case "/nos-solutions/" + technos.BI:
              return <>
                <Helmet prioritizeSeoTags>
                  <title>Solutions BI & Data - Business Intelligence, reporting automatisé</title>
                  <meta name="description" content="Pilotez votre business avec une stratégie Data-driven grâce à la BI et des tableaux de bords utiles. TED est partenaire Tableau Software & Power BI." />
                  <meta property="og:title" content="Solutions BI & DATA" />
                  <meta property="og:description" content="Pilotez votre business avec une stratégie Data-driven grâce à la BI et des tableaux de bords utiles. TED est partenaire Tableau Software & Power BI." />
                </Helmet>

                {/* ----- First Page - Presentation ----- */}
                <Presentation
                  image={solutions[3].img}
                  alt={solutions[3].alt}
                  title={solutions[3].longTitle}
                  description={solutions[3].longDescription}
                  description2={solutions[3].longDescription2}
                />

                {/* ----- Our Prestations -----*/}
                <Prestations ourPrestationsRef={ourPrestationsRef} techno={technos.BI} />

                {/* ----- Our Services -----*/}
                <OurServices ourServicesRef={ourServicesRef} techno={technos.BI} />

                {/* ----- Projects -----*/}
                <OurProjects techno={technos.BI} title={"BI & DataViz"} ourProjectsRef={ourProjectsRef} favorite={false} />

                {/* ----- Partners -----*/}
                <Partners location={location} title={"BI & Data"} solution={technos.BI} />
              </>
            case "/nos-solutions/" + technos.BPM:
              return <>
                <Helmet prioritizeSeoTags>
                  <title>Solutions BPM - Audit, Task Mining et Process Mining</title>
                  <meta name="description" content="Identifiez les problèmes dans vos processus métiers et optimisez les grâce au Business Process Management. Nous sommes partenaires UiPath et Celonis." />
                  <meta property="og:title" content="Solutions BPM - Audit, Task Mining et Process Mining" />
                  <meta property="og:description" content="Identifiez les problèmes dans vos processus métiers et optimisez les grâce au Business Process Management. Nous sommes partenaires UiPath et Celonis. " />
                </Helmet>

                {/* ----- First Page - Presentation ----- */}
                <Presentation
                  image={solutions[2].img}
                  alt={solutions[2].alt}
                  title={solutions[2].longTitle}
                  description={solutions[2].longDescription}
                  description2={solutions[2].longDescription2}
                />

                {/* ----- Our Prestations -----*/}
                <Prestations ourPrestationsRef={ourPrestationsRef} techno={technos.BPM} />

                {/* ----- Our Services -----*/}
                <OurServices ourServicesRef={ourServicesRef} techno={technos.BPM} />

                {/* ----- Projects by solution -----*/}
                <OurProjects techno={technos.BPM} title={"BPM"} ourProjectsRef={ourProjectsRef} favorite={false} />

                {/* ----- Partners & tools -----*/}
                <Partners location={location} title={"BPM"} solution={technos.BPM} />
              </>
            case "/nos-solutions/" + technos.DEVOPS:
              return <>

                <Helmet prioritizeSeoTags>
                  <title>Solutions DevOps - Développement & administartion de vos projets</title>
                  <meta name="description" content="Améliorez et rationalisez la collaboration des workflows entre vos équipes de développement et les opérationnels." />
                  <meta property="og:title" content="Solutions DevOps" />
                  <meta property="og:description" content="Améliorez et rationalisez la collaboration des workflows entre vos équipes de développement et les opérationnels." />
                </Helmet>

                {/* ----- First Page - Presentation ----- */}
                <Presentation
                  image={solutions[4].img}
                  alt={solutions[4].alt}
                  title={solutions[4].longTitle}
                  description={solutions[4].longDescription}
                  description2={solutions[4].longDescription2}
                />

                {/* ----- Our Prestations -----*/}
                <Prestations ourPrestationsRef={ourPrestationsRef} techno={technos.DEVOPS} />

                {/* ----- Our Services -----*/}
                <OurServices ourServicesRef={ourServicesRef} techno={technos.DEVOPS} />

                {/* ----- Projects -----*/}
                <OurProjects techno={technos.DEVOPS} title={"DevOps"} ourProjectsRef={ourProjectsRef} favorite={false} />

                {/* ----- Partners -----*/}
                <Partners location={location} title={"DevOps"} solution={technos.DEVOPS} />
              </>
            default:
              break;
          }

        })()}

      </div>
    </HelmetProvider>

  )
}
