import React, { useEffect, useState, useMemo } from 'react'
import { Link } from 'react-router-dom'

// ----- Packages -----
import gsap from 'gsap'
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

// ----- Constants -----
import { courses } from '../../services/constants/components/courses';

// ----- Component -----
import Course from '../courses/Course';

export default function CarouselCourses() {

    const [allCourses, setAllCourses] = useState([])
    const [count, setCount] = useState(0)


    const loadCarousel = useMemo(() => {
        setAllCourses([])
        courses.forEach(course => {
            setAllCourses(arr => [...arr, <Course course={course} />]);
        });
        // eslint-disable-next-line
    }, [courses]);


    useEffect(() => {
        return loadCarousel
    }, [loadCarousel])


    useEffect(() => {
        if (count === 0) {
            setTimeout(() => {
                gsap.to('.alice-carousel', { css: { width: "101%" }, duration: 0 });
                setCount(1)
            }, 1000);
        }
    }, [count])


    return (
        <div id="carouselCourses" className="component">

            <h2>Nos autres formations</h2>

            <div className="container-carousel">

                <AliceCarousel
                    mouseTracking
                    controlsStrategy={'responsive'}
                    items={allCourses}
                    infinite={true}
                    responsive={{
                        0: {
                            items: 1,
                        },
                        768: {
                            items: 2,
                        },
                        1200: {
                            items: 3,
                        }
                    }} />

            </div>

            <div>
                <Link to={"/formations"} className="mt-5 btn-default btn-orange">Voir toutes nos formations</Link>
            </div>

        </div>
    )
}
