import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'

// ----- Packages ----- 
import ReactPaginate from "react-paginate";

// ----- Components -----
import FilterProjectsBy from './FilterProjectsBy'
import Project from '../projectsCarousel/Project'


export default function Projects({ sectorsRef }) {
    // ----- Get params -----
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();

    const [filteredArray, setFilteredArray] = useState([])

    const [selectedSectors, setSelectedSectors] = useState([])
    const [selectedJobs, setSelectedJobs] = useState([])

    // ----- Pagination -----
    const [currentPage, setCurrentPage] = useState(0)

    const PER_PAGE = 6;
    const offset = currentPage * PER_PAGE;
    const pageCount = Math.ceil(filteredArray.length / PER_PAGE);

    function handlePageClick({ selected: selectedPage }) {
        setCurrentPage(selectedPage);
    }

    return (
        <div className="component" id="filter-component" ref={sectorsRef}>

            <h2>Filtrer les projets par:</h2>

            <FilterProjectsBy
                setFilteredArray={setFilteredArray}
                setSelectedSectors={setSelectedSectors}
                selectedSectors={selectedSectors}
                setSelectedJobs={setSelectedJobs}
                selectedJobs={selectedJobs}
                query={query}
            />

            <div id="allProjects">
                {filteredArray.slice(offset, offset + PER_PAGE)
                    .map((project, index) => {
                        return <Project project={project} key={index} />
                    })
                }
            </div>

            {(() => {

                if (filteredArray.length > PER_PAGE) {
                    return <div className="container-centered">
                        <ReactPaginate
                            previousLabel={"←"}
                            nextLabel={"→"}
                            pageCount={pageCount}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            previousLinkClassName={"pagination__link"}
                            nextLinkClassName={"pagination__link"}
                            disabledClassName={"pagination__link--disabled"}
                            activeClassName={"pagination__link--active"}
                        />
                    </div>
                }

            })()}

            {filteredArray.length === 0 &&
                <span id="no-result">Désolé, aucun projet ne correspond à ces filtres</span>
            }
        </div>
    )
}
