// ----- Images ----- 
import RpaIcon from '../../../images/solutions/rpa.png';
import IaIcon from '../../../images/solutions/ia.png';
import BpmIcon from '../../../images/solutions/bpm.png';
import BiIcon from '../../../images/solutions/bi.png';
import DevOpsIcon from '../../../images/solutions/devops.png';

import RpaIllustration from '../../../images/solutions/solutionRPA.svg';
import IaIllustration from '../../../images/solutions/solutionIA.svg';
import BpmIllustration from '../../../images/solutions/solutionBPM.svg';
import BiIllustration from '../../../images/solutions/solutionBi.svg';
import DevOpsIllustration from '../../../images/solutions/solutionDevops.svg';

// ----- Constants
import { technos } from '../../../services/constants/technos'


export const solutions = [
    {
        id: 1,
        title: "RPA",
        longTitle: "RPA : L'automatisation au service des métiers et de l'excellence opérationelle",
        description: "La RPA (Robotic Process automation) permet de robotiser des tâches chronophages et sans valeur ajoutée. Découvrez nos réalisations avec UiPath, Power Automate, Winshuttle et Automation Anywhere.",
        longDescription: "Que ce soit aux achats, au service logistique, au sein des équipes marketing, au département RH ou encore côté Finance et comptabilité, il existe de multiples tâches qui sont réalisées quotidiennement par vos collaborateurs.",
        longDescription2: "Ces actions chronophages et sans valeur ajoutée, ont un impact sur la productivité et la motivation des équipes. C'est pourquoi nous accompagnons nos clients afin d'optimiser, fluidifier, transformer et digitaliser des processus métiers.",
        link: "/nos-solutions/" + technos.RPA,
        reverse: true,
        icon: RpaIcon,
        iconAlt: "RPA, automatisation de process",
        img: RpaIllustration,
        alt: "Solutions RPA robotisation Uipath",
        findOutMore: "/projets/", // si besoin d'un lien "en savoir plus sur la page solution, et non d'un rdv"
    },
    {
        id: 2,
        title: "IA",
        longTitle: "Avec l'IA vous avez le pouvoir de donner plus d'intelligence à vos robots et fiabiliser votre reporting",
        description: "L'Intelligence Artificielle permet de rendre plus intelligents nos robots. Nous avons la capacité de traiter des tâches et des processus plus complexes comme la lecture de mails, la récupération de données, la génaration de textes, l'analyse de données...",
        longDescription: "L'intelligence Artificielle semble parfois très éloignée de notre quotidien. Néanmoins, il existe aujourd'hui de nombbreux algorithmes assez simples à mettre en oeuvre.",
        longDescription2: "Ces programmes, éduqués par nos Data Scientists permettent d'aller plus loin dans l'autonomie des robots que nous créeons. Ils permettent aussi de réaliser des tâches encore plus complexes qu'avec des logiciels standards du marché.",
        link: "/nos-solutions/" + technos.IA,
        reverse: false,
        icon: IaIcon,
        iconAlt: "Robotique et intelligence artificielle",
        img: IaIllustration,
        alt: "Solutions IA, machine & deep Learning",

    },
    {
        id: 3,
        title: "BPM",
        longTitle: "Business Process Management (BPM) : Identifiez les problèmes de vos collaborateurs et optimisez les",
        description: "Le Business Process Management est une manière de travailler qui permet d'analyser des processus métiers, de détecter des points de friction et d'estimer des potentiels de transformation. Le Task Mining et le Process Mining nous permettent d'aller à des degrés plus ou moins micro de cet audit des processus.",
        longDescription: "Les objectifs du BPM sont d‘apporter à une entreprise un audit des processus métiers. Grâce à l'analyse des résultats, on peut améliorer sa performance, sa productivité et réduire ses coûts.",
        longDescription2: "Le Task Mining et le Process Mining permettent d'analyser plus ou moins en détail les étapes d'un processus afin d'en identifier la source des problèmes.",
        link: "/nos-solutions/" + technos.BPM,
        reverse: true,
        icon: BpmIcon,
        iconAlt: "Business Process Management",
        img: BpmIllustration,
        alt: "Solutions Business Process Management, processus métiers",

    },
    {
        id: 4,
        title: "BI & Data",
        longTitle: "Business Intelligence & Data : Piloter son business avec une stratégie Data-driven",
        description: "Un processus métier est régi par des règles de gestion, opéré par des humains qui traitent de la données dans des logiciels. Nous accompagnons nos clients dans la structuration de leur modèle BI et nous développons et automatisons les indicateurs qui leur permettent de piloter leurs activités.",
        longDescription: 'Que ce soit aux achats, au service logistique, au sein des équipes marketing, au département RH ou encore côté Finance et comptabilité, des milliers de données sont manipulées quotidiennement par vos collaborateurs.',
        longDescription2: 'La donnée a une valeur sous-estimée dans de nombreuses entreprises. C\'est pourquoi nous aidons denombreux clients à mettre en place une culture de la Data, déployer un modèle BI, construire des tableaux de bords qui permettront une meilleure prise de décisions de vos équipes',
        link: "/nos-solutions/" + technos.BI,
        reverse: false,
        icon: BiIcon,
        iconAlt: "Business Intelligence & Data",
        img: BiIllustration,
        alt: "Solutions BI & Data Visualisation",
    },
    {
        id: 5,
        title: "DevOps",
        longTitle: "Développer ses propres applications, des algorithmes, des API pour aller plus loin dans l'hyper automatisation",
        description: "Notre équipe Devops permet de piloter, orchestrer et gérer les applications et nos API que nous développons en sur-mesure pour nos clients.",
        longDescription: "Le DevOps offre un framework conçu pour dynamiser et améliorer le développement d'applications, de robots et permet la mise à disposition de nouvelles fonctionnalités aux utilisateurs.",
        longDescription2: "Les outils DevOps améliorent et rationnalisent la collaboration des workflows de développement et d'opérations en automatisant les tâches chronophages, manuelles ou statiques des phases d'intégration, de développement, de test, de déploiement ou de surveillance.",
        link: "/nos-solutions/" + technos.DEVOPS,
        reverse: true,
        icon: DevOpsIcon,
        iconAlt: "DevOps de projets",
        img: DevOpsIllustration,
        alt: "Développement et administration des projets",
    },

]