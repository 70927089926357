import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// ----- Component -----
import Loader from '../tools/LoaderComponent'

//----- Helper -----
import { unescapeHtml } from '../../services/helpers/unescapeHtml'


export default function LatestPost({ post, index }) {

    const navigate = useNavigate()

    const [title, setTitle] = useState("")


    useEffect(() => {
        if (post.content.rendered) {
            const wpm = 250;
            const words = post?.content?.rendered.trim().split(/\s+/).length;
            const time = Math.ceil(words / wpm);
            document.getElementById("post-reading-time-" + post?.id).innerText = time + " min de lecture";
        }
        if (post.title) {
            setTitle(unescapeHtml(post.title.rendered))
        }
        // eslint-disable-next-line
    }, [post])


    return (
        <article key={index} onClick={() => navigate("/blog/" + post?.slug)} className="recent-post">

            {post.medias[0] !== "undefined" ? <img src={post?.medias[0]?.source_url} alt={post?.medias[0]?.alt_text} /> : <Loader />}

            <div className="post-content">

                <h4 className="post-title">{title && title}</h4>
               
                <hr />

                <div className="post-footer">
                    <p className="post-reading-time" id={"post-reading-time-" + post?.id}></p>
                </div>

            </div>
        </article>
    )
}
