import React from 'react'

// ----- Component -----
import Presentation from '../components/whoWeAre/Presentation'
import SomeNumbers from '../components/whoWeAre/SomeNumbers'
import Poles from '../components/whoWeAre/Poles'
import CorporateValues from '../components/whoWeAre/CorporateValues'

// ----- Packages -----
import { Helmet, HelmetProvider } from 'react-helmet-async';


export default function WhoWeAre() {
    return (
        <HelmetProvider>

            <Helmet prioritizeSeoTags>
                <title>Qui sommes-nous?</title>
                <meta name="description" content="Découvrez en plus sur Ted - Nos missions, nos valeurs et notre champ d'action" />
                <meta property="og:title" content="Qui sommes-nous?" />
                <meta property="og:description" content="Découvrez en plus sur Ted - Nos missions, nos valeurs et notre champ d'action" />
            </Helmet>

            <div className="page" id="whoWeAre">

                <Presentation />

                <SomeNumbers />

                <Poles />

                <CorporateValues />

            </div>
        </HelmetProvider>
    )
}
