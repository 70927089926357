import React from 'react'

export default function TermsAndPolicies() {
    return (
        <div>
            <section id="termsAndPolicies" className="component">
                <h1>Conditions et politiques</h1>
                <div className="subtitle">

                    TED consulting vous informe, et vous donnez votre consentement à la sauvegarde et à l’utilisation des cookies sur notre site,
                    que ce soit via l’acceptation directe via notre message d’avertissement ou en poursuivant la navigation sur notre site.

                    <br />
                    <br />

                    Un cookie est un fichier qui se télécharge sur votre ordinateur lorsque vous accédez à certaines pages web.
                    Les cookies permettent à un site web, entre autre, de rassembler et récupérer les informations sur les habitudes de navigation
                    d’un utilisateur ou du matériel qu’il utilise, et, en fonction des informations récupérées et de la manière dont est utilisé ce matériel,
                    les utiliser pour identifier les utilisateurs.

                    <br />
                    <br />

                    Nous utilisons actuellement trois types de cookies:
                    <ul>
                        <li>
                            Des cookies de localisation, ou cookies de fonctionnalité, qui attribuent à chaque utilisateur enregistré sur notre site un
                            identifiant unique correspondant à sa localisation et au langage utilisé. Ces cookies ont une durée de vie de 30 jours
                        </li>
                        <li>
                            Des cookies techniques Web Plateforme de type cookie-agreed, qui sont utilisés à travers la page web pour optimiser la navigation de l’utilisateur.
                            Ils ne collectent aucune information personnelle et ont une durée de vie de 90 jours.
                        </li>
                        <li>Des cookies de session, d’une durée de vie de 23 jours, qui permettent de suivre les actions de l’utilisateur au cours de navigation.</li>
                    </ul>

                 
                    <br />

                    Nous vous informons que vous pouvez configurer votre navigateur pour refuser ou accepter les cookies,
                    ou le configurer pour que ces cookies s’effacent automatiquement une fois que vous quittez le navigateur, ou le dispositif que vous utilisez.

                    <br />
                    <br />

                    La configuration des cookies sur votre navigateur se réalise de la manière suivante :

                    <ul>
                        <li>Google Chrome dans <i>Paramètres &gt; Confidentialité &gt; Paramètres de contenus &gt; Cookies</i>.</li>

                        <li>  Firefox dans <i>Options &gt; Vie Privée</i>.</li>

                        <li>Internet Explorer dans <i>Options Internet &gt; Confidentialité &gt; Avancée</i>.</li>

                        <li>Safari dans <i>Préférences &gt; Privée</i>.</li>
                    </ul>


                    <br />
                    <br />

                    En modifiant ces paramètres, vous pouvez accepter tous les cookies, être informé.e de l’activation d’un cookie ou encore refuser tous les cookies.
                    Vous pouvez facilement vous soustraire aux cookies en modifiant les propriétés de votre navigateur ou en utilisant la navigation privée.
                    Vous pouvez également les effacer.

                    <br />

                    La modification de ces paramètres peut impacter la navigation et l’accès à certaines pages du site.

                    <br />

                    TED n’est pas responsable des cookies issus de site tiers et vous invite à consulter la politique de cookies de ces sites.

                </div>
            </section >


        </div >
    )
}
