import React, { useState } from 'react'

// ----- Packages ----- 
import Button from 'react-bootstrap/Button';

// ----- Services ----- 
import { scrollScaleText } from '../services/helpers/revealText'

// ----- Component ----- 
import ContactUs from './ContactUs'

export default function NeedInformations() {
    scrollScaleText("#contactUs h2")

    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);

    return (
        <div className="component" id="contactUs">
            <h2>Besoin d'informations?</h2>

            <div>
                <Button className="btn-default btn-orange" variant="primary" onClick={handleShow}>Nous contacter</Button>
            </div>

            <ContactUs show={show} setShow={setShow}/>
        </div>
    )
}
