import React, { useState } from 'react'
import { Link } from 'react-router-dom'


// ----- Packages -----
import gsap, { Power2 } from "gsap"
import Button from 'react-bootstrap/Button';

// ----- Images -----
import LogoRosace from "../../images/logos/rosace-white.png"
import Logo from "../../images/logos/logo-white.png"
import Youtube from '../../images/icons/youtube-green.svg'
import Linkedin from '../../images/icons/linkedin-green.svg'
import Arrow from '../../images/icons/arrow-down.svg'


// ----- Constants -----
import { solutions } from '../../services/constants/components/solutions'
import { sectorsLabel, jobsLabel } from '../../services/constants/components/labelFilter'

// ----- Components -----
import ContactUs from '../ContactUs'


export default function HeaderTablet() {

  const [toggled, setToggled] = useState(false);
  const [nav, setNav] = useState(false);

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);

  // ----- Show one section -----
  const toggledSection = (e) => {
    const idToggled = e.currentTarget.id;
    const tl = gsap.timeline()

    setToggled(toggled ? false : true)

    if (!toggled) {
      tl.to('#' + idToggled + '-section-dropdown', { display: 'flex', opacity: 1, y: 0, duration: .5, ease: Power2 });
      tl.set('#' + idToggled + ' .arrow', { transform: 'rotate(180deg)' });

    } else {
      tl.to('#' + idToggled + '-section-dropdown', { display: 'none', opacity: 0, y: '-25px', duration: .5, ease: Power2 });
      tl.set('#' + idToggled + ' .arrow', { transform: 'rotate(0deg)' });
    }
  };


  // ----- Show menu -----
  const toggledNavbar = (e) => {
    const tl = gsap.timeline()

    setNav(nav ? false : true)
    if (nav) {
      tl.to('#dropdown-container', { display: 'none', opacity: 0, y: "-350px", duration: .2, ease: Power2 });
    } else {
      tl.to('#dropdown-container', { display: 'flex', opacity: 1, y: 0, duration: .5, ease: Power2 });

    }
  }



  return (
    <header id="header-tablet">

      <nav>
        <div>
          <Link to="/">
            <img src={nav ? Logo : LogoRosace} id="logo" alt="logo TED" />
          </Link>
        </div>

        <svg onClick={toggledNavbar} id="dropdown-icon" className="bi bi-filter-right" xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" viewBox="0 0 16 16">
          <path d="M14 10.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 .5-.5zm0-3a.5.5 0 0 0-.5-.5h-7a.5.5 0 0 0 0 1h7a.5.5 0 0 0 .5-.5zm0-3a.5.5 0 0 0-.5-.5h-11a.5.5 0 0 0 0 1h11a.5.5 0 0 0 .5-.5z" />
        </svg>
      </nav>



      <div id="dropdown-container">


        <div>
          <Link to="/qui-sommes-nous" className="title-section pt-0">Qui sommes-nous ?</Link>
        </div>


        <div className="dropdown-divider"></div>


        <div className="dropdown-section" id="solutions" onClick={toggledSection}>
          <div className="title-section">
            <p>Solutions</p>
            <img src={Arrow} alt="flèche blanche" className="arrow"/>
          </div>

          <div className="toggled-section" id="solutions-section-dropdown">
            {solutions && solutions.map((solution, index) =>
              <Link to={solution.link} key={index}><img src={solution.icon} alt={solution.iconAlt} />{solution.title}</Link>
            )}
          </div>
        </div>


        <div className="dropdown-divider"></div>


        <div className="dropdown-section" id="sectors" onClick={toggledSection}>
          <div className="title-section">
            <p >Nos Projets</p>
            <img src={Arrow} alt="flèche blanche" />
          </div>

          <div className="toggled-section" id="sectors-section-dropdown">
            <b>Par métier</b>
            {jobsLabel && jobsLabel.map((job, index) =>
              <a href={"/nos-projets?jobs=" + job.value} key={index}><img src={job.icon} alt={"icone par métier"} />{job.title}</a>
            )}

            <b className="mt-4">Par secteur</b>
            {sectorsLabel && sectorsLabel.map((sector, index) =>
              <a href={"/nos-projets?jobs=" + sector.value} key={index}><img src={sector.icon} alt={"icone par métier"} />{sector.title}</a>
            )}
          </div>
        </div>


        <div className="dropdown-divider"></div>


        <div className="dropdown-section" id="resources" onClick={toggledSection}>
          <div className="title-section">
            <p>Ressources</p>
            <img src={Arrow} alt="flèche blanche" />
          </div>

          <div className="toggled-section" id="resources-section-dropdown">
            <Link to="/blog">Blog</Link>
            <Link to="/partenaires">Nos partenaires</Link>
            <Link to="/formations">Nos formations</Link>
            <Link to="/portfolio">Portfolio</Link>
            <Link to={"/conditions-et-politiques"}>Conditions et politiques</Link>
          </div>
        </div>


        <div className="dropdown-divider"></div>


        <div id="social-media">
          <a href={process.env.REACT_APP_YOUTUBE_CHANNEL} target="_blank" rel="noopener noreferrer"><img src={Youtube} alt="Youtube rounded" /></a>
          <a href={process.env.REACT_APP_LINKEDIN_CHANNEL} target="_blank" rel="noopener noreferrer"><img src={Linkedin} alt="Linkedin rounded" /></a>
        </div>


        <div className="dropdown-divider"></div>


        <div>
          <Button className="title-section btn-default btn-white" variant="primary" onClick={handleShow}>Nous contacter</Button>
        </div>
        {/*  ---- Popup Contact Us ----- */}
        <ContactUs show={show} setShow={setShow} />

      </div>

    </header>
  )
}