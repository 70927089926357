import React from 'react'
import { Link } from 'react-router-dom'

export default function Course({ course, index }) {
    return (
        <div className="partner" key={index}>
            <div className="image-container">
                <img src={course?.image} alt={course?.alt} className={`${course?.size === "xl" ? "xl-logo" : course?.size === "xs" ? "xs-logo" : "md-logo"}`} />
            </div>
            <h3>Formation {course?.title}</h3>
            <p>{course?.description}</p>

            <Link to={"/formations/" + course?.slug} className="btn-default btn-green">Voir les formations</Link>
        </div>
    )
}
