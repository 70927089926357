// ----- Images ----- 
import Diagram from '../../../../images/solutions/tailorMade/diagram.png';
import Statistics from '../../../../images/solutions/tailorMade/statistics.png';


export const solutionIa = [
    {
        id: 1,
        title: "Consulting",
        descriptions: [
            {
                id: 1,
                text: "Etude de faisabilité et cadrage de projet IA",
            },
            {
                id: 2,
                text: "Benchmark et recommandations sur le choix des modèles d'IA",
            },
            {
                id: 3,
                text: "Etude d'éligibilité au CII et CIR",
            },
            {
                id: 4,
                text: "Maquettage & atelier de Design Thinking avec les métiers",
            },
        ],
        img: Diagram,
        alt: "Deux personnes qui regardent des statistiques sur une tablette mobile",
    },
    {
        id: 2,
        title: "Intégration",
        descriptions: [
            {
                id: 5,
                text: "Réalisation de POC & Pilotes",
            },
            {
                id: 6,
                text: " Développement d'algorithmes , modèles de Deep et Machine Learning (ML)",
            },
            {
                id: 7,
                text: "Rédaction des mémoires de recherches exploratoires",
            },
            {
                id: 8,
                text: "Orchestration & ordonnancement des scripts et algorithmes",
            },
        ],
        img: Statistics,
        alt: "De la Dataviz sur une tablette mobile",
    },


]