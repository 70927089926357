import React, { useState, useEffect } from 'react'

// ----- Images -----
import RosaceGreen from '../../images/logos/rosace-green.png'
import RosaceBlue from '../../images/logos/rosace-blue.png'
import RosaceOrange from '../../images/logos/rosace-orange.png'

// ----- Constants -----
import { courses } from '../../services/constants/components/courses';
import { technos } from '../../services/constants/technos';

// ----- Component -----
import Course from './Course'

export default function AllCourses() {

    const [coursesRpa, setCoursesRpa] = useState([])
    const [coursesBi, setCoursesBi] = useState([])
    const [coursesInternal, setCoursesInternal] = useState([])

    useEffect(() => {
        setCoursesRpa([])
        setCoursesBi([])
        setCoursesInternal([])

        courses.forEach(course => {
            if (course.title) {
                if (course.technos.includes(technos.RPA)) {
                    setCoursesRpa(arr => [...arr, course])
                }
              
                if (course.technos.includes(technos.BI)) {
                    setCoursesBi(arr => [...arr, course])
                }
                if (course.type) {
                    setCoursesInternal(arr => [...arr, course])
                }
            }
        });
        // eslint-disable-next-line
    }, [courses])


    return (
        <>
            <section>
                <div className="title-container">
                    <img src={RosaceGreen} className="logo" alt="rosace verte du logo TED" />
                    <h2>Formations hyper Automation & RPA</h2>
                </div>

                <div className="partners-container container-centered">
                    {
                        coursesRpa && coursesRpa.map((course, index) =>
                            <Course course={course} index={index}/>
                        )
                    }
                </div>
            </section>

            <section className="component bg-lightblue">
                <div className="title-container">
                    <img src={RosaceBlue} className="logo" alt="rosace verte du logo TED" />
                    <h2>Formations Business Intelligence & Data Viz</h2>
                </div>

                <div className="partners-container container-centered">
                    {
                        coursesBi && coursesBi.map((course, index) =>
                            <Course course={course} index={index} />
                        )
                    }
                </div>
            </section>


            <section className="component">
                <div className="title-container">
                    <img src={RosaceOrange} className="logo" alt="rosace verte du logo TED" />
                    <h2>Formations Cadrage & Pilotage de projets</h2>
                </div>

                <div className="partners-container container-centered">
                    {
                        coursesInternal && coursesInternal.map((course, index) =>
                            <Course course={course} index={index} />
                        )
                    }
                </div>
            </section>
        </>
    )
}
