import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

// ----- Services -----
import { getPostBlogBySlug, getTagsByPost, getRecentPosts, getMedias } from '../services/axios/blog'

// ----- Helper -----
import { unescapeHtml } from '../services/helpers/unescapeHtml'

// ----- Component -----
import Presentation from '../components/blogPost/Presentation'
import Loader from '../components/tools/LoaderComponent'
import LatestPost from '../components/blogPost/LatestPost'

// ----- Packages -----
import { Helmet, HelmetProvider } from 'react-helmet-async';

export default function BlogPost() {
    const params = useParams();

    const [loading, setLoading] = useState("")
    const [loadingRecentPosts, setLoadingRecentPosts] = useState("")

    const [post, setPost] = useState("")
    const [tags, setTags] = useState([])
    const [date, setDate] = useState("")
    const [title, setTitle] = useState("")

    // --------------------
    // Recent posts at the bottom of the page
    // --------------------
    const [recentPosts, setRecentPosts] = useState([])
    const [allMedias, setAllMedias] = useState([])
    const [recentPostsWithMedias, setRecentPostsWithMedias] = useState([])

    useEffect(() => {
        setLoading(true)
        getPostBlogBySlug(setPost, setLoading, params.slug)
    }, [params.slug])

    useEffect(() => {
        if (post.content) {
            let description = document.getElementById('post-content-' + params?.slug);
            description.innerHTML = unescapeHtml(post?.content?.rendered);

            let linkList = document.querySelectorAll('#post-content-' + params?.slug + " a");

            linkList.forEach((item) => {
                item.setAttribute('target', '_blank');
                item.classList.add("link-content");
              })


            const wpm = 250;
            const words = post?.content?.rendered.trim().split(/\s+/).length;
            const time = Math.ceil(words / wpm);
            document.getElementById("post-reading-time-" + post?.id).innerText = time + " min de lecture";
        }
        if (post.date) {
            const datePublished = new Date(post.date)
            setDate(datePublished.toLocaleDateString("fr"))
        }

        if (post.title) {
            setTitle(unescapeHtml(post.title.rendered))
        }

        if (post.id) {
            getTagsByPost(setTags, post.id)
            setLoadingRecentPosts(true)
            getRecentPosts(setRecentPosts, post.id)
            getMedias(setAllMedias)
        }


    }, [post, params.slug])



    useEffect(() => {
        if (recentPosts.length > 0 && allMedias.length > 0) {
            setRecentPostsWithMedias(recentPosts.map(post => {
                const medias = allMedias.filter(media => media.post === post.id);

                return { ...post, medias };
            }));

            setLoadingRecentPosts(false)
        }
        // eslint-disable-next-line
    }, [allMedias, recentPosts])

    return (
        <HelmetProvider>

            <Helmet prioritizeSeoTags>
                <title>{post.yoast_head_json && "Blog - " + unescapeHtml(post.yoast_head_json.title)}</title>
                <meta name="description" content={post.yoast_head_json && unescapeHtml(post.yoast_head_json.description)} />
                <meta property="og:title" content={post.yoast_head_json && "Blog - " + unescapeHtml(post.yoast_head_json.title)} />
                <meta property="og:description" content={post.yoast_head_json && unescapeHtml(post.yoast_head_json.description)} />
                <link rel="canonical" href={post.yoast_head_json && post.yoast_head_json.canonical} />
            </Helmet>
            <div className="page" id="blogPostPage">
                {/* ------------- Banner ---------------- */}
                <Presentation />

                <div id="blogPost-container">

                    {loading ? <Loader /> :
                        <article className="post-content-rendered">

                            <div className="post-header">
                                <p className="post-date">{date && date}</p>
                                <p className="post-reading-time" id={"post-reading-time-" + post?.id}></p>
                            </div>
                            <h2 className="post-title">{title && title}</h2>

                            {/* -------------Rendered WP content ---------------- */}
                            <section className="post-content" id={"post-content-" + params?.slug}></section>

                            {/* ------------- Tags by post ---------------- */}
                            <div className="post-tags">
                                {tags && tags.map((tag, index) => {
                                    return <p key={index}>{tag?.name}</p>
                                })}
                            </div>

                            <div className="hr"></div>

                            <div className="post-footer">
                                <div id="social-media">
                                    <a href={process.env.REACT_APP_YOUTUBE_CHANNEL} target="_blank" rel="noopener noreferrer">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="#ffa384" className="bi bi-youtube" viewBox="0 0 16 16">
                                            <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z" />
                                        </svg>
                                    </a>
                                    <a href={process.env.REACT_APP_LINKEDIN_CHANNEL} target="_blank" rel="noopener noreferrer">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="#ffa384" className="bi bi-linkedin" viewBox="0 0 16 16">
                                            <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </article>
                    }

                    <div className="recent-posts-component">
                        <h3>Posts récents</h3>
                        <a href="/blog" id="see-posts" >Voir tout</a>

                        {loadingRecentPosts ? <Loader /> :
                            <div className="recent-posts-container">
                                {recentPostsWithMedias && recentPostsWithMedias.map((post, index) => {
                                    return <LatestPost key={index} post={post} />
                                })}
                            </div>
                        }

                    </div>

                </div>

            </div>
        </HelmetProvider>
    )
}
