import React from 'react'

// ----- Images -----
import RosaceOrange from '../../images/logos/rosace-orange.png'
import RosaceBlue from '../../images/logos/rosace-blue.png'
import RosaceGreen from '../../images/logos/rosace-green.png'
import Mathias from '../../images/mathias.jpg'

export default function Presentation() {
    return (
        <>
            <div id="rosace-blue">
                <img src={RosaceBlue} alt={"rosace bleue du logo TED consulting"} />
            </div>

            <div className="component">
                <div className="container-centered ">
                    <h1>Nous accompagnons les entreprises dans leur transformation digitale et dans l'implémentation de plateformes d'automatisation</h1>
                </div>
            </div>



            <div id="mission">
                <div className="title">
                    <img className="rosace" src={RosaceOrange} alt={"rosace orange du logo TED consulting"} />
                    <h2>Notre mission</h2>
                </div>

                <p className="description">
                    Nous savons qu'il existe de multiples manières de faire grandir une entreprise.
                    Néanmoins, il est plus ardu de trouver une méthode où ce qui est positif pour les résultats financiers est également bon pour les collaborateurs.
                    Chez Ted, nous pensons que les entreprises peuvent croître et développer leur environnement de travail grâce à l'Hyper Automation.
                    <br />
                    C'est pourquoi nous avons développé un savoir-faire unique réunissant la Data, la RPA, l'IA, le BPM et le DevOps,
                    afin de répondre aux enjeux opérationnels et pragmatiques de nos clients.
                </p>
            </div>

            <div id="history">
                <div className="history-text-side">
                    <div className="title">
                        <img className="rosace" src={RosaceGreen} alt={"rosace verte du logo TED consulting"} />
                        <h2>Notre histoire</h2>
                    </div>
                    <p className="description">
                        Ted a été fondée en 2016 par Mathias Chaumon en tant que Freelance et aujourd'hui, nous avons bien grandi!
                        <br />
                        Désormais référencé au sein de nombreuses entreprises du CAC40, principalement sur des missions de conseil
                        et d'intégration de solutions et de plateformes permettant aux entreprises d'automatiser, fluidifier, sécuriser, optimiser n'importe quel processus ou tâche.
                    </p>
                </div>
                <div>
                    <img id="mathias" src={Mathias} alt={"Mathias Chaumon, CEO de TED consulting, RPA & hyperautomation"} />
                </div>
            </div>
        </>
    )
}
