// ----- Constants -----
import { jobs } from '../jobs';
import { sectors } from '../sectors';

// ----- Sectors
import BankAndInsuranceSectors from '../../../images/project/Banque_ Assurance.png';
import PharmaSector from '../../../images/project/Pharmaceutique.png';
import EnergySector from '../../../images/project/Energie.png';
import IndustrySector from '../../../images/project/Industrie.png';
import RealEstateSector from '../../../images/project/Immobilier.png';
import ServiceSector from '../../../images/project/Service.png';
import RetailSector from '../../../images/project/Retail.png';


// ----- Jobs
import FinanceJob from '../../../images/project/Finance.png';
import ItJob from '../../../images/project/IT.png';
import SupplyChainJob from '../../../images/project/Supply_Chain.png';
import SavJob from '../../../images/project/Service_client.png';
import RhJob from '../../../images/project/RH.png';
// import DatavizJob from '../../../images/project/Dataviz.png';


export const sectorsLabel = [
    {
        title: "Pharma",
        value: sectors.PHARMA,
        icon: PharmaSector,
    },
    {
        title: "Banque/Assurance",
        value: sectors.BANKANDINSURANCE,
        icon: BankAndInsuranceSectors,
    },
    {
        title: "Retail",
        value: sectors.RETAIL,
        icon: RetailSector,
    },
    {
        title: "Industrie",
        value: sectors.INDUSTRY,
        icon: IndustrySector,
    },
    {
        title: "Service",
        value: sectors.SERVICE,
        icon: ServiceSector,
    },
    {
        title: "Immobilier",
        value: sectors.REALESTATE,
        icon: RealEstateSector,
    },
    {
        title: "Énergie",
        value: sectors.ENERGY,
        icon: EnergySector,
    },
];


export const jobsLabel = [
    {
        title: "Finance",
        value: jobs.FINANCE,
        icon: FinanceJob
    },
    {
        title: "Ressources humaines",
        value: jobs.RH,
        icon: RhJob
    },
    {
        title: "IT",
        value: jobs.IT,
        icon: ItJob,
    },
    {
        title: "Supply chain",
        value: jobs.SUPPLYCHAIN,
        icon: SupplyChainJob,
    },
    {
        title: "SAV",
        value: jobs.SAV,
        icon: SavJob,
    },
]